import MapView from "@arcgis/core/views/MapView";
import Map from "@arcgis/core/Map";
import BasemapToggle from "@arcgis/core/widgets/BasemapToggle";
import { createTileLayer } from "@/helpers";

export const createMapView = (mapDiv) => {
    const satelliteBasemap = "arcgis-imagery"; // Mapa base satelital
    const customBasemap = createTileLayer(); // Mapa base personalizado

    const map = new Map({
        basemap: satelliteBasemap, // Inicia con el mapa satelital
    });

    const view = new MapView({
        map: map,
        center: [-73.129807, 4.752534],
        zoom: 3,
        container: mapDiv.current,
        navigation: {
            mouseWheelZoomEnabled: false,
            browserTouchPanEnabled: false
        }
    });

    view.ui.move("zoom", "top-right");

    view.constraints = {
        minScale: 100000000,
        maxScale: 0,
        rotationEnabled: false
    };

    const basemapToggle = new BasemapToggle({
        view: view,
        nextBasemap: customBasemap // Cambia al mapa base personalizado
    });

    view.ui.add(basemapToggle, "top-right");

    return view;
};
