import { useContext, useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { projectsService } from "@/services";
import { MarketplaceContext } from "@/context";
import { useQuery } from "@/hooks";
import { DetailsCard } from "./DetailsCard";

export const ProjectDetails = () => {
    const idFromSearch = useQuery("id");
    const { projectsRed } = useContext(MarketplaceContext);
    const [projects, dispatch] = projectsRed;
    const isMounted = useRef(true);
    const [selectedProject, setSelectedProject] = useState(null);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        if (idFromSearch) {
            dispatch({ type: 'loading' });
            projectsService.getById(idFromSearch).then(
                project => {
                    if (isMounted.current) {
                        console.log(project)
                        dispatch({ type: 'finished', payload: [project] });
                        setSelectedProject(project);
                    }
                },
                error => {
                    if (isMounted.current) {
                        dispatch({ type: 'error', payload: error });
                    }
                }
            );
        }
    }, [idFromSearch, dispatch]);

    return (
        <Card className="project-details-card">
            {selectedProject && <DetailsCard project={selectedProject} />}
        </Card>
    )
}