import "./scss/App.scss";
import { AppRouter } from "@/routers";

const App = () => {
	return (
		<>
			<AppRouter />
		</>
	);
};

export default App;
