
import { useState, memo} from "react";
import useMeasure from "react-use-measure";
import { Signin, RegisterButton } from "@/pages/auth";
import { useScrollTop } from "@/hooks";
import { useLocation, Link } from "react-router-dom";
import { authenticationService } from "@/services";
import { useHistory } from "react-router";
import { NavMenu, SearchBar, UserMenu } from "./";
import 'bootstrap/dist/css/bootstrap.min.css';

export const Navbar = () => {

	const currentUser = authenticationService.currentUserValue;
	const [ref, bounds] = useMeasure();
    const scrollTop = useScrollTop();

	const history = useHistory();

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const logout = () => {
		authenticationService.logout();
		history.push('/');
	}
	const { pathname } = useLocation();
	const { pathname: page } = useLocation();
	const valorRecuperado = localStorage.getItem('currentUser');


	return (
		<nav ref={ref} className={`gs-navbar${page === "/" ? scrollTop !== 0 ? " scroll" : "" : ""}${page !== "/" ? " page" : ""}`}>
			{currentUser  &&
			<>
				<NavMenu scrollTop={scrollTop}/>
			</>	
				
				}
			{!currentUser && 

				<div>
					<p><Link to="/" className={`nav-link-gs nav-brand ${pathname === "/" ? scrollTop !== 0 ? " scroll" : "" : ""}${pathname !== "/" ? " page" : ""}`}>
					<img src={`${process.env.PUBLIC_URL}/assets/logo.png`} width={100} alt="Ybira logo" className="sponsor-badge"></img>
						</Link></p>
				</div>
			
			}
			
			{page === "/marketplace" && <SearchBar/>}

			<div className={`navbar-menu ${page !== "/" && scrollTop !== 0 ? "scroll" : ""}`}>
				{!currentUser &&
					<>
						<RegisterButton scrollTop={scrollTop} />
						<img  src="/assets/button/login.png" onClick={handleShow} width={200}/>	
					</>
				}
				{currentUser && <UserMenu  user={currentUser} onLogOut={logout}/>}
			</div>
			{ show ? <Signin show={show} handleClose={handleClose} handleShow={handleShow} /> : null}
		</nav >
	);
};
