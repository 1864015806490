import { cards } from "@/helpers";
import useMeasure from "react-use-measure";
import { animated, useSpring, useTrail, config, useSpringRef, useChain } from "react-spring";
import { useEffect, useState } from "react";
import { useScrollTop } from "@/hooks";
import { memo } from "react";

export const HowItWorks = memo(() => {
    const [open, setOpen] = useState(false);
    const [ref, bounds] = useMeasure();
    const scrollTop = useScrollTop();
    const animationRef = useSpringRef();
    const animation = useSpring({
        ref: animationRef,
        config: config.slow,
        delay: 100,
        opacity: open ? 1 : 0,
        transform: open ? "translateY(0%)" : "translateY(-10%)",
    });

    const cardRef = useSpringRef();
    const cardAnimation = useTrail(cards.length, {
        ref: cardRef,
        from: { opacity: 0, transform: "translateY(10%)" },
        opacity: open ? 1 : 0,
        transform: open ? "translateY(0%)" : "translateY(10%)",
        config: config.slow,
        delay: 100,
    });

    useChain([animationRef, cardRef], [0, 0.5]);

    useEffect(() => {
        const { top: topSection } = bounds;
        if (!open && topSection > 0 && scrollTop > topSection - 750) {
            setOpen(true);
        }
    }, [bounds, open, scrollTop]);

    const [flipped, setFlipped] = useState(Array(4).fill(false));

    const handleFlip = (index) => {
        const newFlipped = [...flipped];
        newFlipped[index] = !newFlipped[index];
        setFlipped(newFlipped);
    };

    return (
        <div className="how">
            <h1 className="title">How It Works?</h1>
            <animated.section ref={ref} style={animation}>
                <div className="cards">
                    {cardAnimation.map((style, index) => (
                        <animated.div key={cards[index].step} style={style} className="card">
                            <div className="card-image">
                                <img src={cards[index].image} className="card-image-how" alt={cards[index].step} />
                            </div>
                            <div className="card-content">
                                <p>{cards[index].description}</p>
                            </div>
                        </animated.div>
                    ))}
                </div>
            </animated.section>
            <h1 className="title">Why Ybira?</h1>
            <animated.section ref={ref} style={animation}>
                <div className="resource-cards">
                    {[
                        {
                            image: "https://www.greenhopefund.org/images/imagesamazonas/1.jpg",
                            title: "Individual Contributors and Investors",
                            content: [
                                "- Investors can track their investment, from the donation until the end of the project.",
                                "- Receives Donation Certificate (tax-deductible in some countries).",
                                "- Receives carbon credit when investing in project-related reforestation.",
                            ],
                        },
                        {
                            image: "https://www.greenhopefund.org/images/imagesamazonas/1.jpg",
                            title: "NGOs",
                            content: [
                                "- Connect with investors.",
                                "- Receive investment for project scalability and growth.",
                            ],
                        },
                        {
                            image: "https://www.greenhopefund.org/images/imagesamazonas/1.jpg",
                            title: "Local Communities",
                            content: ["- Generation of job opportunities."],
                        },
                        {
                            image: "https://www.greenhopefund.org/images/imagesamazonas/1.jpg",
                            title: "Carbon Credit Developers",
                            content: ["- Connect with new projects to develop carbon credits."],
                        },
                    ].map((card, index) => (
                        <div key={index} className="resource-card" onClick={() => handleFlip(index)}>
                            <img src={card.image} alt={card.title} className="resource-card-image" />
                            <div className="resource-card-content">
                                <h3>{card.title}</h3>
                                <ul>
                                    {card.content.map((item, idx) => (
                                        <li key={idx}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </animated.section>
        </div>
    );
});
