import React, { useRef, useEffect, useState, useContext } from "react";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import { useRouteMatch } from "react-router-dom";
import { getCentroid, createMapView } from "@/helpers";
import { MarketplaceContext } from "@/context";
import { createTileLayer } from "@/helpers";
import { useHistory, useLocation } from "react-router-dom";

export const BubbleMap = () => {
  const mapDiv = useRef();
  const [view, setView] = useState();
  const [graphicLayer, setGraphicLayer] = useState();
  const basemap = createTileLayer();
  
  const { projectsRed } = useContext(MarketplaceContext);
  const [projects] = projectsRed;

  const { url } = useRouteMatch();

  const history = useHistory();
  const location = useLocation();

  const seeDetails = (id, geometry) => {
    history.push(`${url}/project?id=${id}`);
    zoomToLocation(geometry);
  };

  useEffect(() => {
    const initView = createMapView(mapDiv);
    setView(initView);
    const layer = new GraphicsLayer();
    setGraphicLayer(layer);
    initView.map.add(layer);
  }, []);

  useEffect(() => {
    if (projects.data && graphicLayer && view) {
      graphicLayer.removeAll();

      projects.data.forEach((project) => {
        const centroid = getCentroid(project.boundaries);
        const point = { type: "point", longitude: centroid.x, latitude: centroid.y };
        const pointGraphic = new Graphic({
          geometry: point,
          symbol: {
            type: "picture-marker",
            url: `${process.env.PUBLIC_URL}/assets/location.png`,
            width: "40px",
            height: "40px"
          },
          attributes: { projectID: project.id }
        });
        graphicLayer.add(pointGraphic);
      });

      view.on("click", function (event) {
        view.hitTest(event).then(function (response) {
          if (response.results.length) {
            const graphic = response.results.filter(result => result.graphic.layer === graphicLayer)[0].graphic;
            seeDetails(graphic.attributes.projectID, graphic.geometry);
          }
        });
      });

      // Initial map center and zoom
      if (projects.data.length > 1) {
        view.center = [-73.129807, 4.752534];
        view.zoom = 3;
      }
    }
  }, [projects, graphicLayer, view]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const projectId = params.get("id");

    if (projectId && projects.data && graphicLayer && view) {
      const project = projects.data.find(p => p.id === projectId);

      if (project) {
        const coordinates = project.boundaries.map(b => [b.longitude, b.latitude]);
        const polygonGraphic = new Graphic({
          geometry: {
            type: "polygon",
            rings: coordinates
          },
          symbol: {
            type: "simple-fill",
            outline: {
              color: [255, 255, 255],
              width: 2
            }
          }
        });

        graphicLayer.add(polygonGraphic);
        view.when(() => {
          view.goTo({
            target: polygonGraphic,
            zoom: 15
          }, {
            duration: 3000,
            easing: "ease-in-out"
          });
        });
      }
    }
  }, [location.search, projects, graphicLayer, view]);

  const zoomToLocation = (geometry) => {
    const isPolygon = geometry.type === "polygon";
    const opts = {
      duration: 3000,
      easing: "ease-in-out"
    };

    if (isPolygon) {
      view.goTo({
        target: geometry,
        zoom: 15
      }, opts).catch(error => {
        console.error("Error zooming to location:", error);
      });
    } else {
      view.goTo({
        center: [geometry.longitude, geometry.latitude],
        zoom: 15
      }, opts).catch(error => {
        console.error("Error zooming to location:", error);
      });
    }
  };

  return (
    <div className="map-div" ref={mapDiv}></div>
  );
};
