import React, { useState, useEffect, useContext } from 'react';
import { Card, Carousel } from "react-bootstrap";
import { CircularProgress } from "@/components/progress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare, faInstagramSquare, faTwitterSquare, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import AgendaIcon from "../../assets/flat-icon/svg/agenda.svg";
import TreeIcon from "../../assets/flat-icon/svg/tree.svg";
import WebSiteIcon from "../../assets/flat-icon/svg/website.svg";
import AreaGraphIcon from "../../assets/flat-icon/svg/area-graph.svg";
import LocationIcon from "../../assets/flat-icon/svg/location.svg";
import MoneyIcon from "../../assets/flat-icon/svg/money.svg";
import PersonIcon from "../../assets/flat-icon/svg/person.svg";
import { FlatIcon } from "@/components/icon";
import { prefixNum } from "@/helpers";
import { InvestButton } from ".";
import { useHistory } from 'react-router-dom';
import { MarketplaceContext } from "@/context";
import { authenticationService } from "@/services";

// Import SDG images
import objective1 from "../../assets/ods/01.jpg";
import objective2 from "../../assets/ods/02.jpg";
import objective3 from "../../assets/ods/03.jpg";
import objective4 from "../../assets/ods/04.jpg";
import objective5 from "../../assets/ods/05.jpg";
import objective6 from "../../assets/ods/06.jpg";
import objective7 from "../../assets/ods/07.jpg";
import objective8 from "../../assets/ods/08.jpg";
import objective9 from "../../assets/ods/09.jpg";
import objective10 from "../../assets/ods/10.jpg";
import objective11 from "../../assets/ods/11.jpg";
import objective12 from "../../assets/ods/12.jpg";
import objective13 from "../../assets/ods/13.jpg";
import objective14 from "../../assets/ods/14.jpg";
import objective15 from "../../assets/ods/15.jpg";
import objective16 from "../../assets/ods/16.jpg";
import objective17 from "../../assets/ods/17.jpg";

const objectives = [
  { id: 1, img: objective1 },
  { id: 2, img: objective2 },
  { id: 3, img: objective3 },
  { id: 4, img: objective4 },
  { id: 5, img: objective5 },
  { id: 6, img: objective6 },
  { id: 7, img: objective7 },
  { id: 8, img: objective8 },
  { id: 9, img: objective9 },
  { id: 10, img: objective10 },
  { id: 11, img: objective11 },
  { id: 12, img: objective12 },
  { id: 13, img: objective13 },
  { id: 14, img: objective14 },
  { id: 15, img: objective15 },
  { id: 16, img: objective16 },
  { id: 17, img: objective17 },
];

export const DetailsCard = ({ project }) => {
  const history = useHistory();
  const [invest, setInvest] = useState({
    Investments: project.amountPerYear,
    Tree_cost: project.treeCost,
    Trees: project.goalT,
  });

  const { ngosRed } = useContext(MarketplaceContext);
  const [ngos, dispatch] = ngosRed;
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    if (!ngos.data || ngos.data.length !== 1) {
      dispatch({ type: 'loading' });
      dispatch({ type: 'finished', payload: [project.owner] });
    }
  }, [project.owner, dispatch, ngos.data]);

  useEffect(() => {
    const subscription = authenticationService.currentUser.subscribe(user => {
      setUserType(user ? user.type : null);
    });
    return () => subscription.unsubscribe();
  }, []);

  const goBack = () => {
    history.goBack();
  }

  return (
    <>
      <Carousel>
        {project.photos.map((photo, index) => (
          <Carousel.Item key={index}>
            <img className="d-block w-100" src={photo} alt={`Slide ${index}`} />
          </Carousel.Item>
        ))}
      </Carousel>
      <Card.Body className="card-body">
        <div className="project-details-header">
          <div className="name-location">
            <div>
              <p className="project-name">{project.name}</p>
              <div className="d-flex align-items-center pt-2">
                <FlatIcon>
                  <img src={LocationIcon} alt="location icon" style={{ width: "100%" }} />
                </FlatIcon>
                <p className="desc" style={{ fontWeight: "600" }}>{project.countryCode}</p>
              </div>
            </div>
          </div>
          <div className="project-kpi">
            {userType === "investor" && <InvestButton project={project} />}
          </div>
        </div>

        <div className="project-details-about">
          <h1 className="title">About</h1>
          {project.description && project.description.map((projectDesc, i) => (
            <p key={i} className="about-desc">{projectDesc}</p>
          ))}
          <div className="project-kpis">
            {project.boundaries && (
              <div className="kpi">
                <FlatIcon>
                  <img src={AreaGraphIcon} alt="boundaries icon" style={{ width: "100%" }} />
                </FlatIcon>
                <p className="desc">Boundaries: <span className="value">{project.boundaries.length}</span></p>
              </div>
            )}
            {project.amountPerYear && (
              <div className="kpi">
                <FlatIcon>
                  <img src={MoneyIcon} alt="money icon" style={{ width: "100%" }} />
                </FlatIcon>
                <p className="desc">Investment Amount per Year: <span className="value">${project.amountPerYear}</span></p>
              </div>
            )}
            {project.durationYears && (
              <div className="kpi">
                <FlatIcon>
                  <img src={AgendaIcon} alt="duration icon" style={{ width: "100%" }} />
                </FlatIcon>
                <p className="desc">Duration: <span className="value">{project.durationYears} years</span></p>
              </div>
            )}
            {project.phase && (
              <div className="kpi">
                <FlatIcon>
                  <img src={AgendaIcon} alt="phase icon" style={{ width: "100%" }} />
                </FlatIcon>
                <p className="desc">Phase: <span className="value">{project.phase}</span></p>
              </div>
            )}
            {project.startDate && (
              <div className="kpi">
                <FlatIcon>
                  <img src={AgendaIcon} alt="start date icon" style={{ width: "100%" }} />
                </FlatIcon>
                <p className="desc">Start Date: <span className="value">{project.startDate}</span></p>
              </div>
            )}
            {project.endDate && (
              <div className="kpi">
                <FlatIcon>
                  <img src={AgendaIcon} alt="end date icon" style={{ width: "100%" }} />
                </FlatIcon>
                <p className="desc">End Date: <span className="value">{project.endDate}</span></p>
              </div>
            )}
            <div className="kpi">
              <FlatIcon>
                <img src={TreeIcon} alt="carbon credit icon" style={{ width: "100%" }} />
              </FlatIcon>
              <p className="desc">Carbon Credit Generation: <span className="value">{project.carbonCreditGeneration ? 'Yes' : 'No'}</span></p>
            </div>
            <div className="kpi">
              <FlatIcon>
                <img src={TreeIcon} alt="tax exemption icon" style={{ width: "100%" }} />
              </FlatIcon>
              <p className="desc">Tax Exemption: <span className="value">{project.taxExemption ? 'Yes' : 'No'}</span></p>
            </div>
          </div>
        </div>

        <div className="project-details-about">
          <h1 className="title">Sustainable Development Goals</h1>
          <div className="d-flex flex-wrap">
            {project.sdgs && project.sdgs.map(id => {
              const obj = objectives.find(o => o.id === id);
              return obj ? (
                <div key={id} style={{ margin: '5px' }}>
                  <img src={obj.img} alt={`Objective ${id}`} width={50} height={50} />
                </div>
              ) : null;
            })}
          </div>
        </div>

        <div className="project-details-about">
          <h1 className="title">Project's Progress</h1>
          <div className="project-progress">
            <div className="circular-progress mt-4">
              <CircularProgress
                currentT={project.analytics ? project.analytics.totalPlanted : 0}
                goalT={project.goalT}
                width={200}
                iconSrc={TreeIcon}
                label="Objective"
              />
            </div>
            <div className="progress-info">
              <div className="total-count">
                <p className="counter">$ {prefixNum(project.amountPerYear, invest.Investments)} USD</p>
                <p className="title">Total Investments</p>
              </div>
            </div>
          </div>
        </div>

        <h1 className="title">Contact</h1>
        <div className="project-owner-details">
          {project.owner && (
            <>
              <div className="owner-info">
                <img src={project.owner.img} alt="profile" className="profile-img" />
                <p className="owner-name">{project.owner.name || project.owner.username}</p>
              </div>
              <div className="owner-contact">
                <p className="desc">{project.owner.email}</p>
                {project.owner.website && (
                  <p className="desc">
                    <a href={project.owner.website} target="_blank" rel="noopener noreferrer">
                      {project.owner.website}
                    </a>
                  </p>
                )}
                <div className="social-icons">
                  {project.owner.facebook && (
                    <a
                      href={project.owner.facebook.startsWith('http') ? project.owner.facebook : `https://www.facebook.com/${project.owner.facebook}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="icon"
                    >
                      <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
                    </a>
                  )}
                  {project.owner.instagram && (
                    <a
                      href={project.owner.instagram.startsWith('http') ? project.owner.instagram : `https://www.instagram.com/${project.owner.instagram}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="icon"
                    >
                      <FontAwesomeIcon icon={faInstagramSquare} size="2x" />
                    </a>
                  )}
                  {project.owner.twitter && (
                    <a
                      href={project.owner.twitter.startsWith('http') ? project.owner.twitter : `https://www.twitter.com/${project.owner.twitter}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="icon"
                    >
                      <FontAwesomeIcon icon={faTwitterSquare} size="2x" />
                    </a>
                  )}
                  {project.owner.linkedin && (
                    <a
                      href={project.owner.linkedin.startsWith('http') ? project.owner.linkedin : `https://www.linkedin.com/in/${project.owner.linkedin}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="icon"
                    >
                      <FontAwesomeIcon icon={faLinkedin} size="2x" />
                    </a>
                  )}
                </div>

              </div>
            </>
          )}
        </div>
      </Card.Body>
    </>
  );
};

export default DetailsCard;
