import { useState, useEffect } from 'react';
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import { userService } from "@/services";

export const Details = (props) => {
    const userId = props.user.id;

    const [loading, setLoading] = useState(false);
    const [serverResponse, setServerResponse] = useState(null);
    const [profile, setProfile] = useState({
        name: '',
        email: '',
        website: '',
        facebook: '',
        instagram: '',
        twitter: '',
        linked_in: '',
    });
    const [errors, setErrors] = useState({
        website: '',
        facebook: '',
        instagram: '',
        twitter: '',
        linked_in: '',
    });

    useEffect(() => {
        if (userId) {
            userService.getUserProfile(userId).then(data => {
                setProfile(prev => ({
                    ...prev,
                    website: data.website ?? '',
                    facebook: data.facebook ?? '',
                    instagram: data.instagram ?? '',
                    twitter: data.twitter ?? '',
                    linked_in: data.linked_in ?? '',
                    name: props.user.name ?? '',
                    email: props.user.email ?? ''
                }));
            }).catch(error => console.error('Failed to fetch profile data:', error));
        }
    }, [userId, props.user.name, props.user.email]);

    const handleInputChange = (field, value) => {
        const isLink = value.includes('http') || value.includes('www') || value.includes('.com') || value.includes('.net') || value.includes('.org');
        
        if (field !== 'website' && isLink) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [field]: 'Please enter only the username, not a link.'
            }));
            return;
        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                [field]: ''
            }));
        }

        const updatedProfile = {
            ...profile,
            [field]: value
        };
        setProfile(updatedProfile);
        props.onChange(updatedProfile);
    };

    return (
        <div id="details-container">
            <Form className="form-container">
                <Form.Group className="mb-3" controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter name" value={profile.name} readOnly />
                </Form.Group>
                <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="text" placeholder="Enter email" value={profile.email} readOnly />
                </Form.Group>
                <Form.Group className="mb-3" controlId="website">
                    <Form.Label>Website</Form.Label>
                    <Form.Control type="text" placeholder="Enter website" value={profile.website} onChange={e => handleInputChange('website', e.target.value)} />
                    {errors.website && <Alert variant="danger">{errors.website}</Alert>}
                </Form.Group>
                <Form.Group className="mb-3" controlId="facebook">
                    <Form.Label>Facebook User</Form.Label>
                    <Form.Control type="text" placeholder="Enter Facebook" value={profile.facebook} onChange={e => handleInputChange('facebook', e.target.value)} />
                    {errors.facebook && <Alert variant="danger">{errors.facebook}</Alert>}
                </Form.Group>
                <Form.Group className="mb-3" controlId="instagram">
                    <Form.Label>Instagram User</Form.Label>
                    <Form.Control type="text" placeholder="Enter Instagram" value={profile.instagram} onChange={e => handleInputChange('instagram', e.target.value)} />
                    {errors.instagram && <Alert variant="danger">{errors.instagram}</Alert>}
                </Form.Group>
                <Form.Group className="mb-3" controlId="twitter">
                    <Form.Label>Twitter User</Form.Label>
                    <Form.Control type="text" placeholder="Enter Twitter" value={profile.twitter} onChange={e => handleInputChange('twitter', e.target.value)} />
                    {errors.twitter && <Alert variant="danger">{errors.twitter}</Alert>}
                </Form.Group>
                <Form.Group className="mb-3" controlId="linkedin">
                    <Form.Label>LinkedIn User</Form.Label>
                    <Form.Control type="text" placeholder="Enter LinkedIn" value={profile.linked_in} onChange={e => handleInputChange('linked_in', e.target.value)} />
                    {errors.linked_in && <Alert variant="danger">{errors.linked_in}</Alert>}
                </Form.Group>
            </Form>
        </div>
    );
};

export default Details;
