import React, { useEffect, useState } from "react";
import config from "config";

export const Admin = () => {
  const [combinedData, setCombinedData] = useState([]);
  const [projectData, setProjectData] = useState([]);

  useEffect(() => {
    fetchCombinedData();
    fetchProjectData();
  }, []);

  const fetchCombinedData = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/combined-data/`);
      if (response.ok) {
        const responseData = await response.json();
        console.log("Combined Data:", responseData); // Verificación de datos
        setCombinedData(responseData);
      } else {
        console.error("Error fetching combined data:", response.status);
      }
    } catch (error) {
      console.error("Error fetching combined data:", error);
    }
  };

  const fetchProjectData = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/projects/`);
      if (response.ok) {
        const responseData = await response.json();
        console.log("Project Data:", responseData); // Verificación de datos
        setProjectData(responseData);
      } else {
        console.error("Error fetching project data:", response.status);
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  const handleConfirm = async (user_id) => {
    try {
      const userToUpdate = combinedData.find(item => item.user_id === user_id);

      if (!userToUpdate) {
        console.error('No se encontró el usuario con el ID proporcionado:', user_id);
        return;
      }

      console.log("Datos enviados:", {
        id: userToUpdate.investment.id, // Asegúrate de que envías el ID correcto de la inversión
        status: 'done'
      });

      const response = await fetch(`${config.apiUrl}/invest/change`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: userToUpdate.investment.id, // Asegúrate de que envías el ID correcto de la inversión
          status: 'done'
        })
      });

      if (response.ok) {
        const updatedCombinedData = combinedData.map(item => {
          if (item.user_id === user_id) {
            return { ...item, investment: { ...item.investment, status: 'done' } };
          }
          return item;
        });

        setCombinedData(updatedCombinedData);
        console.log('Estado actualizado:', user_id);
      } else {
        const errorData = await response.json();
        console.error('Error al actualizar el estado:', response.status, errorData);
      }
    } catch (error) {
      console.error('Error al enviar la solicitud:', error);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <div style={{ width: "80%", maxHeight: "60vh", overflowY: "auto" }}>
        <h1>Tabla de Datos</h1>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={{ border: "1px solid black", padding: "8px" }}>User ID</th>
              <th style={{ border: "1px solid black", padding: "8px" }}>Email</th>
              <th style={{ border: "1px solid black", padding: "8px" }}>Inversion</th>
              <th style={{ border: "1px solid black", padding: "8px" }}>Estado</th>
              <th style={{ border: "1px solid black", padding: "8px" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {combinedData.map((item) => (
              <tr key={item.user_id}>
                <td style={{ border: "1px solid black", padding: "8px" }}>{item.user_id}</td>
                <td style={{ border: "1px solid black", padding: "8px" }}>{item.investment.emailAddress || "-"}</td>
                <td style={{ border: "1px solid black", padding: "8px" }}>{item.investment.cantidad || "-"}</td>
                <td style={{ border: "1px solid black", padding: "8px" }}>{item.investment.status || "-"}</td>
                <td style={{ border: "1px solid black", padding: "8px" }}>
                  {item.investment.status !== 'done' ? (
                    <button onClick={() => handleConfirm(item.user_id)} style={{ backgroundColor: "green", color: "white", border: "none", padding: "8px 16px", borderRadius: "4px", cursor: "pointer" }}>Confirm</button>
                  ) : (
                    <span>Confirmed</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <h1>Proyectos</h1>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={{ border: "1px solid black", padding: "8px" }}>Nombre del Proyecto</th>
              <th style={{ border: "1px solid black", padding: "8px" }}>Descripción</th>
              <th style={{ border: "1px solid black", padding: "8px" }}>Fecha de Creación</th>
            </tr>
          </thead>
          <tbody>
            {projectData.map((project, index) => (
              <tr key={index}>
                <td style={{ border: "1px solid black", padding: "8px" }}>{project.name}</td>
                <td style={{ border: "1px solid black", padding: "8px" }}>{project.description_line1 || project.description_line2 || project.description_line3 || "-"}</td>
                <td style={{ border: "1px solid black", padding: "8px" }}>{project.created_at}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
