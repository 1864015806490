import {Count} from "./";
import { animated, config, useSpring, useTrail } from "react-spring";
import { RegisterButton } from "@/pages/auth";
import  {projectsService} from "@/services/projects.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";


export const Header = () => {

	const [animation] = useSpring(() => ({
		from: { value: 0, opacity: 0, transform: "translateY(-20%)" },
		delay: 500,
		opacity: 1,
		transform: "translateY(0%)",
		config: config.slow
	}));

	const titles = [{ num: 1334034, title: "planted trees", delay: 700 }, { num: 4590, title: "projects", delay: 1400 }, { num: 654, title: "investors", delay: 2100 }];

	const [countSpring] = useTrail(titles.length, () => ({
		from: { value: 0, opacity: 0, transform: "translateY(-0%)" },
		delay: 300,
		opacity: 1,
		transform: "translateY(30%)",
		config: config.stiff
	}));

	return (
		<header className={`masthead`}>
			<animated.div style={animation} >
				<h1 className="masthead-title">
					<span className="title1">Connecting People to build a greener future</span>
				</h1>
				{ /*
				<h1 className="masthead-title">
					<span className="title2">TRACK YOUR INVESTMENT </span>
				</h1>
				*/}

				<div className="masthead-subtitle">
					<h2 className="masthead-description">Make a better World connecting individual and investors with sustainable and environmental projects helping local communities in development countries
					</h2>
				</div>

				<div className="masthead-button">
					<RegisterButton/>
				</div>

				<div className="counters">
					{ /*
					countSpring && countSpring.map((element, index) => (
						<Count key={titles[index].title} animated={element} delay={titles[index].delay} number={titles[index].num} text={titles[index].title} />
					))
					*/}
				</div>
			</animated.div>
			{ /*
			<div className="scroll-down">
				<FontAwesomeIcon icon={faAngleDown} />
			</div>
			*/}
		</header>
	);
};