import config from "config";
import { AppUser, authenticationService } from "./";
import { authHeader, handleResponse } from "@/helpers";

import NonProfitAnalytics from './models/NonProfitAnalytics';
import InvestorAnalytics from './models/InvestorAnalytics';
import DashboardAnalytics from "./models/DashboardAnalytics";
import OtherProject from "./models/OtherProject";

const getUser = async (isDashboard, id) => {
  const opts = { method: "GET", headers: {} };
  if (isDashboard) {
    return authenticationService.currentUserValue;
  } else if (!isDashboard && id !== null) {
    const url = `${config.apiUrl}/AppUsers(${id})`;
    const resp = await fetch(url, opts).then(handleResponse);
    const user = new AppUser(resp);
    return user;
  }
};

const getAnalytics = async (type, id) => {
  const opts = { method: "GET", headers: {} };
  const collection = (type === 'nonprofit') ? 'NonProfitAnalytics' : 'InvestorAnalytics';
  const url = `${config.apiUrl}/${collection}?$filter=APPUSER_ID eq '${id}'`;
  //const resp = await fetch(url, opts).then(handleResponse);
  
  const analytics = (type === 'nonprofit') ? 
  new NonProfitAnalytics({
    "ID": "5dd8a190-f15e-11eb-9a03-0242ac130003",
    "createdAt": null,
    "createdBy": null,
    "modifiedAt": null,
    "modifiedBy": null,
    "Facebook": null,
    "Instagram": null,
    "Twitter": null,
    "LinkedIn": null,
    "EmailAddress": "greener@green.com",
    "DisplayName": "Greener Green",
    "DisplayPicture": "/assets/example-profile-img/profile3.jpg",
    "Language_code": "EN",
    "Currency_code": "USD",
    "RegisteredName": "Greener Green Reforestations",
    "RegisteredID": "75400069212",
    "RegisteredAddress_ID": "846577f7-1b03-4ce0-b84d-6a48b5d2fb4b",
    "WebsiteURL": "www.green.com.br"
  }) : 
    new InvestorAnalytics({
      "ID": "f1a3ab74-f157-11eb-9a03-0242ac130003",
      "RegisteredName": "Reforesawesome Institute",
      "RegistrationId": "65472384578",
      "DisplayName": "Refawins",
      "CountryCode": "EN",
      "Webaddress": "www.refawins.com",
      "Email": "refawins@gmail.com",
      "Channels": {
          "Facebook": "https://www.facebook.com/refawins",
          "Instagram": "https://www.instagram.com/refawins/",
          "Twitter": "https://twitter.com/refawins",
          "LinkedIn": "https://www.linkedin.com/company/refawins/"
      },
      "Picture": null,
      "User": "5f7a0a34-f159-11eb-9a03-0242ac130003"
  });
  return analytics
};

const getProjectAnalytics = async (type, id) => {
  const opts = { method: "GET", headers: {} };

  // Get Project Analytics information
  const field = (type === 'nonprofit') ? 'OWNER_APPUSER_ID' : 'INVESTOR_ID';
  //const analyticsUrl = `${config.apiUrl}/ProjectAnalytics?$filter=${field} eq '${id}'`;
  //const resp = await fetch(analyticsUrl, opts).then(handleResponse);
  const analytics = [
    {
      "ID": 14,
      "OWNER_APPUSER_ID": "20",
      "CENTROID_LATITUDE": 37.7749,
      "CENTROID_LONGITUDE": -122.4194,
      "WORKERCOUNT": 32,
      "CERTIFIER_APPUSER_ID": "cert_567",
      "FOLLOWER_ID": "follow_345",
      "INVESTOR_ID": "invest_123",
      "PLANTINGAREA_M2": 12000,
      "PLANTINGDENSITY_M2": 150,
      "SOWCOST": 350,
      "CONSERVATIONCOST": 250,
      "BLOCKAREA_M2": 6000,
      "TOTALBLOCKCAPACITY": 15,
      "BLOCKPLANTINGCAPACITY": 12,
      "TOTALPLANTINGCAPACITY": 90,
      "BLOCKOFFSETAVERAGE_TCO2E_YEAR": 0.6,
      "BLOCKINITIALCOST": 2100,
      "BLOCKUPKEEPCOST": 120,
      "CREATEDAT": "2023-09-07T10:30:00Z",
      "OFFSETAVERAGE_TCO2E_YEAR_TREE": 0.3,
      "TOTALTREESPLANTED": 5400,
      "TOTALOFFSETEXPECTED_TCO2E": 1100,
      "TOTALBLOCKSPURCHASED": 0,
      "TOTALGROSS": 0,
      "TOTALNET": 0,
      "FOLLOWERCOUNT": 0,
      "INVESTORCOUNT": 55
    },
    {
      "ID": 15,
      "OWNER_APPUSER_ID": "20",
      "CENTROID_LATITUDE": 37.7749,
      "CENTROID_LONGITUDE": -122.4194,
      "WORKERCOUNT": 0,
      "CERTIFIER_APPUSER_ID": "cert_567",
      "FOLLOWER_ID": "follow_345",
      "INVESTOR_ID": "invest_123",
      "PLANTINGAREA_M2": 12000,
      "PLANTINGDENSITY_M2": 150,
      "SOWCOST": 350,
      "CONSERVATIONCOST": 250,
      "BLOCKAREA_M2": 6000,
      "TOTALBLOCKCAPACITY": 15,
      "BLOCKPLANTINGCAPACITY": 12,
      "TOTALPLANTINGCAPACITY": 90,
      "BLOCKOFFSETAVERAGE_TCO2E_YEAR": 0.6,
      "BLOCKINITIALCOST": 2100,
      "BLOCKUPKEEPCOST": 120,
      "CREATEDAT": "2023-09-07T10:30:00Z",
      "OFFSETAVERAGE_TCO2E_YEAR_TREE": 0.3,
      "TOTALTREESPLANTED": 5400,
      "TOTALOFFSETEXPECTED_TCO2E": 1100,
      "TOTALBLOCKSPURCHASED": 0,
      "TOTALGROSS": 11000,
      "TOTALNET": 0,
      "FOLLOWERCOUNT": 1050,
      "INVESTORCOUNT": 55
    },
    {
      "ID": 16,
      "OWNER_APPUSER_ID": "20",
      "CENTROID_LATITUDE": 37.7749,
      "CENTROID_LONGITUDE": -122.4194,
      "WORKERCOUNT": 0,
      "CERTIFIER_APPUSER_ID": "cert_567",
      "FOLLOWER_ID": "follow_345",
      "INVESTOR_ID": "invest_123",
      "PLANTINGAREA_M2": 12000,
      "PLANTINGDENSITY_M2": 150,
      "SOWCOST": 350,
      "CONSERVATIONCOST": 250,
      "BLOCKAREA_M2": 6000,
      "TOTALBLOCKCAPACITY": 15,
      "BLOCKPLANTINGCAPACITY": 12,
      "TOTALPLANTINGCAPACITY": 90,
      "BLOCKOFFSETAVERAGE_TCO2E_YEAR": 0.6,
      "BLOCKINITIALCOST": 2100,
      "BLOCKUPKEEPCOST": 120,
      "CREATEDAT": "2023-09-07T10:30:00Z",
      "OFFSETAVERAGE_TCO2E_YEAR_TREE": 0.3,
      "TOTALTREESPLANTED": 5400,
      "TOTALOFFSETEXPECTED_TCO2E": 1100,
      "TOTALBLOCKSPURCHASED": 0,
      "TOTALGROSS": 11000,
      "TOTALNET": 0,
      "FOLLOWERCOUNT": 1050,
      "INVESTORCOUNT": 55
    },
  ];
  
  // Get Projects information
  const projectsUrl = `${config.apiUrl}/projects/user/${id}`;
  const resp2 = await fetch(projectsUrl, opts).then(handleResponse);
  console.log(resp2);
  const projects = resp2;
  console.log(projects)

  // Merge Analytics with Projects
  let merged = [];
  analytics.forEach(item => {
    const project = projects.find(project => item.ID === project.id);
    if (project) { // Check if a project is found
      const projectAnalytics = new DashboardAnalytics(project, item);
      merged.push(projectAnalytics.toMap());
    }
  });

  return merged;
};



const createProject = async (data) => {
  const owner = authenticationService.currentUserValue;
  
  // Splitting description into three lines
  const description = data.description || "";

  const description_line1 = description;
  const description_line2 = '';
  const description_line3 = '';

  // Forming the payload for the API
  const apiData = {
    owner_appuser_id: owner.id, // Assuming the owner has an ID attribute
    name: data.name,
    description_line1: description_line1,
    description_line2: description_line2,
    description_line3: description_line3,
    country_code: data.country,
    currency_code: data.currency,
    amount_per_year: data.amount,
    duration_years: data.duration,
    planting_area_m2: 0,
    planting_density_m2: 0,
    worker_count: 0,
    tree_cost: 0,
    coordinates: data.coordinates,
    sdgs: data.sdgs,
    phase: data.phase,
    start_date: data.startDate,
    end_date: data.endDate,
    carbon_credit_generation: data.carbonCreditGeneration,
    tax_exemption: data.taxExemption,
  };

  const opts = {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(apiData),
  };

  const url = `${config.apiUrl}/project/create/`;
  const resp = await fetch(url, opts).then(handleResponse);
  
  return resp.ID;
};


const transformBoundariesDataFormat = (data) => {
  console.log(data)
  return {
    project_id: data.Project_ID,
    index: data.Index,
    latitude: data.lat,
    longitude: data.lng
  };
};

const createBoundaries = async (projectId, boundaries) => {
  console.log(boundaries)
  const url = `${config.apiUrl}/boundaries/create/`;
  const boundariesRequests = boundaries.map(item => {
    const transformedItem = transformBoundariesDataFormat(item);
    transformedItem.id_project = projectId;
    const opts = {
      method: "POST",
      headers: {"content-type": "application/json"},
      body: JSON.stringify(transformedItem),
    };
    return fetch(url, opts);
  });

  await Promise.all(boundariesRequests);
  return;
};

const createPlantingCost = async (id, cost) => {
  const opts = {
    method: "POST",
    headers: {"content-type": "application/json"},
    //body: JSON.stringify(item),
  };
  const url = `${config.apiUrl}/PlantingCosts`;
  await fetch(url, opts).then(handleResponse);
  return;
};

function getUserInvestedProjects(userId) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.apiUrl}/invest/investor/${userId}`, requestOptions).then(handleResponse);
}

const uploadImages = async (projectId, formData) => {
  const opts = {
    method: "POST",
    body: formData,
  };
  const url = `${config.apiUrl}/api/upload-project-images/${projectId}/`;
  await fetch(url, opts).then(handleResponse);
};

export const dashboardService = {
  getUser,
  getAnalytics,
  getProjectAnalytics,
  createProject,
  createBoundaries,
  createPlantingCost,
  getUserInvestedProjects,
  uploadImages,
};
