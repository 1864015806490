import React from "react";
import Spinner from "react-bootstrap/Spinner";

export const Loader = () => {
    return (
        <div className="loader animate__fadeOut">
            <img src={`${process.env.PUBLIC_URL}/assets/logoybira.png`} width={150} alt="Ybira logo" className="sponsor-badge"></img>
						
            <Spinner className="spinner"/>
        </div>
    )
}
