
export const about = (url, name, id) => {

    const about = {

        id: `${id}`,
        name: `${name}`,
        url: `${process.env.PUBLIC_URL}/assets/about/${url}`

    }
    return about;
}