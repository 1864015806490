import { authenticationService } from "@/services";

export function handleResponse(response) {
    return response.text().then(text => {
        //console.log("Response Text:", text);  // Debugging line

        try {
            const data = text ? JSON.parse(text) : {};

            if (!response.ok) {
                if ([401, 403].indexOf(response.status) !== -1) {
                    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                    authenticationService.logout();
                    window.location.reload(true);
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        } catch (error) {
            console.error("Error parsing JSON:", error);
            // Handle the error as needed (e.g., return an error object)
            return Promise.reject("Error parsing JSON response");
        }
    });
}
