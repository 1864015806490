import React, { useContext, useEffect, useState } from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ProgressBarProject } from "@/components/progress";
import { InvestButton } from "./";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { authenticationService } from "@/services";
import { MarketplaceContext } from "@/context";

export const ProjectCard = ({ project }) => {
    const { projectsRed } = useContext(MarketplaceContext);
    const [projects, dispatch] = projectsRed;
    const history = useHistory();
    const { url } = useRouteMatch();
    const [userType, setUserType] = useState(null);

    useEffect(() => {
        const subscription = authenticationService.currentUser.subscribe(user => {
            setUserType(user ? user.type : null);
        });
        return () => subscription.unsubscribe();
    }, []);

    const seeDetails = () => {
        dispatch({ type: 'loading' });
        dispatch({ type: 'finished', payload: [project] });
        history.push(`${url}/project?id=${project.id}`);
    }

    const handleShare = async (e) => {
        e.stopPropagation();
        const shareUrl = `${window.location.origin}/marketplace/project?id=${project.id}`;
        const shareData = {
            title: project.name,
            text: project.description[0] || 'Check out this project!',
            url: shareUrl
        };

        if (navigator.share) {
            try {
                await navigator.share(shareData);
                console.log('Project shared successfully');
            } catch (err) {
                console.error('Error sharing the project', err);
            }
        } else {
            // Copy to clipboard
            try {
                await navigator.clipboard.writeText(shareData.url);
                alert('Link copied to clipboard');
            } catch (err) {
                console.error('Error copying the link to clipboard', err);
            }
        }
    }

    const { analytics } = project;

    useEffect(() => {
        return () => { }
    }, []);

    const projectImage = project.photos && project.photos.length > 0 ? project.photos[0] : project.banner;

    return (
        <Card className="project-card" onClick={seeDetails}>
            <Card.Img src={projectImage} className="banner-img" style={{ objectFit: "cover" }} alt="project ngo" />
            <Card.ImgOverlay className="card-body p-0">
                <div className="project-header">
                    <div className="icons">
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Share this project</Tooltip>}
                        >
                            <div className="icon share" onClick={handleShare}>
                                <FontAwesomeIcon icon={faShareAlt} />
                            </div>
                        </OverlayTrigger>
                    </div>
                    <div className="name-sponsors">
                        <p className="project-name">
                            {project.name}
                        </p>
                        <div className="sponsors">
                            {/* 
                                <img src={`${process.env.PUBLIC_URL}/assets/example-profile-img/airbnb.jpg`} alt="Airbnb logo" className="sponsor-badge"></img>
                                <img src={`${process.env.PUBLIC_URL}/assets/example-profile-img/facebook.png`} alt="Facebook logo" className="sponsor-badge"></img>
                                <img src={`${process.env.PUBLIC_URL}/assets/example-profile-img/coca-cola.png`} alt="Coca Cola logo" className="sponsor-badge"></img>
                                <img src={`${process.env.PUBLIC_URL}/assets/example-profile-img/space-x.jpg`} alt="SpaceX logo" className="sponsor-badge"></img>
                                <img src={`${process.env.PUBLIC_URL}/assets/example-profile-img/airfrance.png`} alt="AirFrance logo" className="sponsor-badge"></img>
                            */}
                        </div>
                    </div>

                    <div className="project-body">
                        <div className="body">
                            <div className="project-desc">
                                {project.description && project.description.map(
                                    (desc, i) => (
                                        <p key={i}>{desc}</p>
                                    )
                                )}
                            </div>

                            <div className="project-kpi">
                                {userType === "investor" && <InvestButton project={project} />}
                                {/* Uncomment if needed
                                <p className="tree-cost">${analytics.sowCost.toFixed(2)} per tree</p>
                                <p className="tree-co2">{analytics.offsetAverageTCo2.toFixed(2)} kg CO<sub>2</sub> per tree</p>
                                <p className="co2-cost">${analytics.co2AverageCost.toFixed(2)} per kg CO<sub>2</sub></p>
                                */}
                            </div>
                        </div>

                        <ProgressBarProject
                            currentT={analytics ? analytics.totalTreesPlanted : 0}
                            goalT={analytics ? analytics.totalPlantingCapacity : 0}
                        />
                    </div>
                </div>

            </Card.ImgOverlay>
        </Card>
    );
}
