
import {investor} from ".";

export const investorImages = () => {

    const investors = [
        investor("aws.jpg", "Amazon Web Services", 1),
        investor("greenhope.jpg", "Green Hope Colombia", 2),
        investor("maisons.jpg", "maisons", 3),
        investor("afdpng.png", "AFD", 4),
        investor("forestever.jpg", "Amazon Web Services", 5),
        investor("rajafoundation.jpg", "Green Hope Colombia", 6),
    ];
    return investors;
}
