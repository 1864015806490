import React, { useState } from 'react';
import {
  Sector,
  LabelList,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
} from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#F12DBC'];

export const Analytics = () => {
  // Estado para los datos de las gráficas
  const [barChartData, setBarChartData] = useState([
    
    {
      name: '',
      cost: 0,
      label: '0',
    },
  ]);



  const [pieChartData, setPieChartData] = useState([
    //{ name: 'Anillo verde de Granada', value: 400 },
    { name: '', value: 0 },
  ]);


  const [lineChartData, setLineChartData] = useState([
    {
      name: '2024-01-01',
      uv: 0,
      pv: 0,
      amt: 0,
    },
    {
      name: '2024-02-01',
      uv: 0,
      pv: 0,
      amt: 0,
    },
    {
      name: '2024-03-01',
      uv: 0,
      pv: 0,
      amt: 0,
    },
    {
      name: '2024-04-01',
      uv: 0,
      pv: 0,
      amt: 0,
    }
    // ... otros datos de línea
  ]);


  // Función para generar datos aleatorios y actualizar los estados
  const updateChartData = () => {
    // Genera nuevos datos aleatorios para cada gráfica
    const newBarChartData = barChartData.map((dataPoint) => ({
      ...dataPoint,
      cost: Math.floor(Math.random() * 5000), // Ejemplo de valor aleatorio
      pv: Math.floor(Math.random() * 5000), // Ejemplo de valor aleatorio
    }));

    const newPieChartData = pieChartData.map((dataPoint) => ({
      ...dataPoint,
      value: Math.floor(Math.random() * 500), // Ejemplo de valor aleatorio
    }));

    const newLineChartData = lineChartData.map((dataPoint) => ({
      ...dataPoint,
      uv: Math.floor(Math.random() * 5000), // Ejemplo de valor aleatorio
      pv: Math.floor(Math.random() * 5000), // Ejemplo de valor aleatorio
    }));

    // Actualiza los estados con los nuevos datos
    setBarChartData(newBarChartData);
    setPieChartData(newPieChartData);
    setLineChartData(newLineChartData);
  };

  return (
    <div>
      <div className='mt-4'>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
          <label class="form-check-label" for="flexRadioDefault1">
            INVESTMENTS
          </label>
        </div>
        <div class="form-check ">
          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
          <label class="form-check-label" for="flexRadioDefault2">
            PROJECTS
          </label>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

        {/* Botón para actualizar datos */}
        <button className='btn btn-success mt-4' onClick={updateChartData}>Actualizar Datos</button>

        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px', width: '80%', margin: '20px' }}>

          {/* Gráfica de Barras */}
          <div>
            <div>
              <h4>INVESTMENT PER PROJET</h4>
            </div>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                width={400}
                height={400}
                data={barChartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 30,
                  bottom: 5,
                }}
                layout="vertical"
              >
                <XAxis type="number" tick={false} />
                <YAxis dataKey="name" type="category" interval={0} />
                <Tooltip />
                <Legend />
                <Bar dataKey="cost" fill="#0fb54e">
                  <LabelList fill='#ffffff' dataKey="label" position="insideTop" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>


          {/* Gráfica de Pastel */}
          <div>
            <div>
              <h4>INVESTMENT PER PROJECT</h4>
            </div>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={pieChartData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  {pieChartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>



        </div>

        {/* Gráfica de Línea */}
        <div style={{ width: '80%', margin: '20px' }}>
          <div>
            <h4>INVESTMENT IN THE LAST 3 MONTHS</h4>
          </div>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              width={500}
              height={300}
              data={lineChartData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 10,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" height={60} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="pv" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default Analytics;