import { BehaviorSubject } from "rxjs";
import { handleResponse } from "../helpers/handleResponse";
import { AppUser } from "./";
import config from "config";

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem("currentUser")));

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value; }
};

async function login(email, password) {
    const requestOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })  
    };

    try {
        const data = await fetch(`${config.apiUrl}/login/`, requestOptions).then(handleResponse);

        if (data && data.user) {
            const user = new AppUser(data.user);
            localStorage.setItem("currentUser", JSON.stringify(user));
            currentUserSubject.next(user);
            return user;
        } else {
            throw new Error("Login failed");
        }
    } catch (error) {
        console.error("There was an error logging in:", error);
    }
}

function logout() {
    localStorage.removeItem("currentUser");
    currentUserSubject.next(null);
}
