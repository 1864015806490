import React from "react";
import { Route, Redirect } from "react-router-dom";
import { authenticationService } from "@/services";

export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        }

        // check if route is restricted by role
        if (roles && roles.indexOf(currentUser.role) === -1) {
            // role not authorised so redirect to home page
            return <Redirect to={{ pathname: "/dashboard"}} />
        }
        if (currentUser.role === "admin") {
            return <Redirect to={{ pathname: "/admin", state: { from: props.location }}} />
        }
    
        return <Component {...props} />
    }} />
);