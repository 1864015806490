import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Alert, ProgressBar, Form, Col, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { dashboardService } from "@/services";
import rawContries from '@/json/Countries2.json';
import rawCurrencies from '@/json/Currencies.json';
import { useLoadScript, GoogleMap, Marker } from '@react-google-maps/api';
import JSZip from 'jszip';

// Import SDG images
import objective1 from "../../assets/ods/01.jpg";
import objective2 from "../../assets/ods/02.jpg";
import objective3 from "../../assets/ods/03.jpg";
import objective4 from "../../assets/ods/04.jpg";
import objective5 from "../../assets/ods/05.jpg";
import objective6 from "../../assets/ods/06.jpg";
import objective7 from "../../assets/ods/07.jpg";
import objective8 from "../../assets/ods/08.jpg";
import objective9 from "../../assets/ods/09.jpg";
import objective10 from "../../assets/ods/10.jpg";
import objective11 from "../../assets/ods/11.jpg";
import objective12 from "../../assets/ods/12.jpg";
import objective13 from "../../assets/ods/13.jpg";
import objective14 from "../../assets/ods/14.jpg";
import objective15 from "../../assets/ods/15.jpg";
import objective16 from "../../assets/ods/16.jpg";
import objective17 from "../../assets/ods/17.jpg";

const objectives = [
  { id: 1, img: objective1 },
  { id: 2, img: objective2 },
  { id: 3, img: objective3 },
  { id: 4, img: objective4 },
  { id: 5, img: objective5 },
  { id: 6, img: objective6 },
  { id: 7, img: objective7 },
  { id: 8, img: objective8 },
  { id: 9, img: objective9 },
  { id: 10, img: objective10 },
  { id: 11, img: objective11 },
  { id: 12, img: objective12 },
  { id: 13, img: objective13 },
  { id: 14, img: objective14 },
  { id: 15, img: objective15 },
  { id: 16, img: objective16 },
  { id: 17, img: objective17 },
];

const libraries = ["places"];

export const ProjectForm = (props) => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [fileLabel, setFileLabel] = useState('');
  const [boundaries, setBoundaries] = useState([]);
  const [progress, setProgress] = useState(0);
  const [map, setMap] = useState(null);
  const [selectedObjectives, setSelectedObjectives] = useState([]);
  const { register, formState: { errors }, handleSubmit, setValue, watch } = useForm();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const countries = rawContries['countries'];
  const currencies = rawCurrencies['currencies'];
  const [coordinates, setCoordinates] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [description, setDescription] = useState('');

  const buttonState = (progress) => {
    if (progress === 0) {
      return 'Submit';
    } else if (progress === 10) {
      return 'Creating project...';
    } else if (progress === 33) {
      return 'Creating boundaries...';
    } else if (progress === 66) {
      return 'Uploading photos...';
    } else {
      return 'Go Back';
    }
  };

  const onSubmit = async (data) => {
    if (progress === 100) {
      return history.push('/dashboard');
    }
  
    if (error == null && (coordinates.length > 0 || boundaries.length > 0)) {
      setProgress(10);
  
      const dataWithCoordinates = {
        ...data,
        coordinates: boundaries.length > 0 ? boundaries : coordinates,
        sdgs: selectedObjectives
      };
  
      console.log('Data with Coordinates:', dataWithCoordinates); // Log the data before sending to backend
  
      const projectId = await dashboardService.createProject(dataWithCoordinates);
      setProgress(33);
  
      if (boundaries.length > 0) {
        await dashboardService.createBoundaries(projectId, boundaries);
      } else if (coordinates.length > 0) {
        await dashboardService.createBoundaries(projectId, coordinates);
      }
  
      setProgress(66);
  
      const formData = new FormData();
      formData.append('project_id', projectId);
      photos.forEach((photo) => {
        formData.append('images', photo);
      });
  
      await dashboardService.uploadImages(projectId, formData);
      setProgress(100);
    } else {
      setError('Coordinates are required.');
    }
  };
  

  const handlePhotoChange = (e) => {
    const files = Array.from(e.target.files);
    setPhotos(files);
  };

  const toggleObjective = (id) => {
    if (selectedObjectives.includes(id)) {
      setSelectedObjectives(selectedObjectives.filter(objId => objId !== id));
    } else {
      setSelectedObjectives([...selectedObjectives, id]);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async (event) => {
      const content = event.target.result;
      if (file.name.endsWith('.kmz')) {
        const zip = new JSZip();
        const zipContent = await zip.loadAsync(content);
        const kmlFile = zipContent.file(/.kml$/i)[0];
        const kmlString = await kmlFile.async("string");
        parseKmlString(kmlString);
      } else {
        parseKmlString(content);
      }
      setFileLabel(file.name);
    };

    if (file.name.endsWith('.kmz')) {
      reader.readAsArrayBuffer(file);
    } else {
      reader.readAsText(file);
    }
  };

  const parseKmlString = (kmlString) => {
    const parser = new window.DOMParser();
    const kml = parser.parseFromString(kmlString, 'application/xml');
    const placemarks = kml.getElementsByTagName('Placemark');
    
    if (placemarks.length > 0) {
      const coords = placemarks[0].getElementsByTagName('coordinates')[0].textContent.trim();
      const [lng, lat] = coords.split(',').map(Number);
      const selectedCoordinate = { lat, lng };
  
      console.log('Parsed KML Coordinate:', selectedCoordinate); // Log the parsed coordinate
      setCoordinates([selectedCoordinate]);
      setBoundaries([selectedCoordinate]);
    }
  };
  

  const onMapLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const descriptionLength = watch("description")?.length || 0;

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading Maps</div>;
  }

  return (
    <Card className="form-container" style={{ marginTop: '1220px' }}>
      <Card.Title className="form-title">{props.project ? 'Edit Project' : 'Add Project'}</Card.Title>
      <Card.Body>
        <ProgressBar now={progress} />
        <div style={{ margin: '20px 0px' }} />
        <Alert show={error !== null} variant='danger' onClose={() => setError(null)} dismissible>
          {error}
        </Alert>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-2" size="sm">
            <Form.Label>Project Name</Form.Label>
            <Form.Control
              size="sm"
              isInvalid={errors.name}
              {...register('name', { required: "Project name is required" })}
            />
            {errors.name && (
              <Form.Control.Feedback type="invalid">
                {errors.name.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-2" size="sm">
            <Form.Label>Description</Form.Label>
            <Form.Control
              size="sm"
              as="textarea"
              maxLength={500}
              isInvalid={errors.description}
              rows={3}
              {...register('description', {
                required: "Project description is required",
                maxLength: {
                  value: 500,
                  message: "The description cannot exceed 500 characters"
                }
              })}
            />
            {errors.description && (
              <Form.Control.Feedback type="invalid">
                {errors.description.message}
              </Form.Control.Feedback>
            )}
            <Form.Text className="text-muted">
              {descriptionLength}/500 characters used
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-2" size="sm">
            <Form.Label>Country</Form.Label>
            <Form.Control as='select' size='sm' isInvalid={errors.country} {...register('country', { required: "Country is required" })}>
              {countries.map((country) => (
                <option key={country['code']} value={country['code']}>{country['name']}</option>
              ))}
            </Form.Control>
            {errors.country && (
              <Form.Control.Feedback type="invalid">
                {errors.country.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-2 row" size="sm">
            <Col xs={2}>
              <Form.Label>Currency</Form.Label>
              <Form.Control as='select' size='sm' isInvalid={errors.currency} {...register('currency', { required: "Currency is required" })}>
                {currencies.map((currency) => (
                  <option key={currency['code']} value={currency['code']}>{currency['code']}</option>
                ))}
              </Form.Control>
              {errors.currency && (
                <Form.Control.Feedback type="invalid">
                  {errors.currency.message}
                </Form.Control.Feedback>
              )}
            </Col>
          </Form.Group>
          <Form.Group className="mb-2" size="sm">
          <Form.Label>Investment Amount per Year</Form.Label>
            <Form.Control
              size="sm"
              type="number"
              isInvalid={errors.amount}
              {...register('amount', {
                required: "Requested amount is required",
                validate: value => value > 0 || "Investment amount must be a positive number"
              })}
            />
            {errors.amount && (
              <Form.Control.Feedback type="invalid">
                {errors.amount.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-2" size="sm">
            <Form.Label>Project Duration (years)</Form.Label>
              <Form.Control
                size="sm"
                type="number"
                isInvalid={errors.duration}
                {...register('duration', {
                  required: "Project duration is required",
                  validate: value => value > 0 || "Project duration must be a positive number"
                })}
              />
              {errors.duration && (
                <Form.Control.Feedback type="invalid">
                  {errors.duration.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          
          <Form.Group className="mb-2" size="sm">
            <Form.Label>Project Phase</Form.Label>
            <Form.Control as='select' size='sm' isInvalid={errors.phase} {...register('phase', { required: "Project phase is required" })}>
              <option value="Planning">Planning</option>
              <option value="Development">Development</option>
              <option value="Implementation">Implementation</option>
              <option value="Completion">Completion</option>
            </Form.Control>
            {errors.phase && (
              <Form.Control.Feedback type="invalid">
                {errors.phase.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-2" size="sm">
            <Form.Label>Project Start Date</Form.Label>
            <Form.Control
              size="sm"
              type="date"
              isInvalid={errors.startDate}
              {...register('startDate', { required: "Project start date is required" })}
            />
            {errors.startDate && (
              <Form.Control.Feedback type="invalid">
                {errors.startDate.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-2" size="sm">
            <Form.Label>Project End Date</Form.Label>
            <Form.Control
              size="sm"
              type="date"
              isInvalid={errors.endDate}
              {...register('endDate', { required: "Project end date is required" })}
            />
            {errors.endDate && (
              <Form.Control.Feedback type="invalid">
                {errors.endDate.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-2" size="sm">
            <Form.Label>Carbon Credit Generation</Form.Label>
            <Form.Check
              type="radio"
              label="Yes"
              value="Yes"
              {...register('carbonCreditGeneration', { required: "Please select an option" })}
            />
            <Form.Check
              type="radio"
              label="No"
              value="No"
              {...register('carbonCreditGeneration', { required: "Please select an option" })}
            />
            {errors.carbonCreditGeneration && (
              <Form.Control.Feedback type="invalid">
                {errors.carbonCreditGeneration.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-2" size="sm">
            <Form.Label>Tax Exemption Certificate</Form.Label>
            <Form.Check
              type="radio"
              label="Yes"
              value="Yes"
              {...register('taxExemption', { required: "Please select an option" })}
            />
            <Form.Check
              type="radio"
              label="No"
              value="No"
              {...register('taxExemption', { required: "Please select an option" })}
            />
            {errors.taxExemption && (
              <Form.Control.Feedback type="invalid">
                {errors.taxExemption.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-2" size="sm">
            <Form.Label>Sustainable Development Goals</Form.Label>
            <div className="d-flex flex-wrap">
              {objectives.map(obj => (
                <div
                  key={obj.id}
                  onClick={() => toggleObjective(obj.id)}
                  className={`objective ${selectedObjectives.includes(obj.id) ? 'selected' : ''}`}
                  style={{
                    cursor: 'pointer',
                    margin: '5px',
                    border: selectedObjectives.includes(obj.id) ? '5px solid green' : '5px solid transparent',
                    borderRadius: '5px',
                    transition: 'border 0.3s'
                  }}
                >
                  <img src={obj.img} alt={`Objective ${obj.id}`} width={70} height={70} />
                </div>
              ))}
            </div>
          </Form.Group>

          <Form.Group className="mb-2" size="sm">
            <Form.Label>Photos (3 to 4 images)</Form.Label>
            <Form.Control
              type="file"
              multiple
              accept="image/*"
              isInvalid={errors.photos}
              {...register('photos', {
                required: "Project photos are required",
                validate: {
                  maxFiles: (files) => files.length <= 4 || "You can upload up to 4 photos",
                  minFiles: (files) => files.length >= 3 || "You need to upload at least 3 photos",
                },
              })}
              onChange={handlePhotoChange}
            />
            {errors.photos && (
              <Form.Control.Feedback type="invalid">
                {errors.photos.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-2" size="sm">
            <Form.Label>Upload KMZ/KML for Boundaries or Click on the Map</Form.Label>
            <Form.Control
              type="file"
              accept=".kml,.kmz"
              onChange={handleFileChange}
            />
            {fileLabel && <Form.Text className="text-muted">{fileLabel}</Form.Text>}
          </Form.Group>
          <Form.Group controlId="" className="mb-2" size="sm">
            <Form.Label>Map</Form.Label>
            <div id="map" style={{ width: '100%', height: '400px' }}>
              <GoogleMap
                id="map"
                mapContainerStyle={{ width: '100%', height: '400px' }}
                zoom={2}
                center={{ lat: 0, lng: 0 }}
                onLoad={onMapLoad}
                onClick={(e) => {
                  const latLng = e.latLng.toJSON();
                  setCoordinates([latLng]);
                  setBoundaries([latLng]);
                }}
              >
                {coordinates.map((coordinate, index) => (
                  <Marker key={index} position={coordinate} />
                ))}
              </GoogleMap>
            </div>
          </Form.Group>
          
          <div style={{ margin: '20px 0px' }} />
          <Button variant="primary" className="btn btn-success w-100" type="submit" disabled={progress > 0 && progress < 100}>
            {buttonState(progress)}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default ProjectForm;
