//import config from "configFake";
import config from "config";
import { BehaviorSubject } from "rxjs";
import { authHeader, handleResponse } from "@/helpers";
import { authenticationService } from "./";
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem("currentUser")));


const currentUser = authenticationService.currentUserValue;

export const userService = {
    getAll,
    getById,
    getCountries,
    getUserInvestment,
    changeAvatar,
    getUserProfile,
    updateUserProfile
};

function getAll() {
    const requestOptions = { method: "GET", headers: authHeader() };
    return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}

function getCountries() {
    const requestOptions = { method: "GET", headers: authHeader() };
    return fetch(`${config.apiUrl}/countries/`, requestOptions).then(handleResponse).then(
        resp => resp
    );
}

function getById(id) {
    const requestOptions = { method: "GET", headers: authHeader() };
    return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function getUserInvestment(userId) {
    const requestOptions = { method: "GET", headers: authHeader() };
    return fetch(`${config.apiUrl}/users/${userId}/investments`, requestOptions).then(handleResponse);
}

function changeAvatar(formData) {
    const userId = currentUser.id;
    console.log(currentUser);
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: formData,
    };

    return fetch(`${config.apiUrl}/api/upload-image/${userId}/`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.text();
        })
        .then((result) => {
            const profileUrl = JSON.parse(result);
            console.log(profileUrl);

            // Actualizar la URL de la imagen en el objeto currentUser
            currentUser.img = profileUrl.image_url;

            // Guardar el objeto actualizado en el local storage
            localStorage.setItem("currentUser", JSON.stringify(currentUser));

            // Notificar la actualización a los observadores
            currentUserSubject.next(currentUser);

            return profileUrl; // Asegúrate de devolver el valor correcto
        })
        .catch((error) => {
            console.error("Error during image upload:", error);
            throw error;  // Propaga el error correctamente
        });
}


function getUserProfile(userId) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${config.apiUrl}/profile/${userId}/`, requestOptions).then(handleResponse);
}

function updateUserProfile(userId, profileData) {
    const requestOptions = {
        method: "PUT",
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(profileData),
    };
    return fetch(`${config.apiUrl}/profile/update/${userId}/`, requestOptions).then(handleResponse);
}