import React from "react";
import { useHistory } from 'react-router-dom';
import { Card, Button } from "react-bootstrap";
import { useWidth } from "@/hooks";
import addproject from "@/assets/menu/addproject.png";

export const AddProjectCard = ({ onToggle }) => {
    const history = useHistory();
    const bodyWidth = useWidth();

    const onRedirect = () => history.push(`/project`);

    return (
        <div className="add-project-card">
            <Card className="summary-card">
                <Card.Body className="card-body">
                    <div className="card-content">
                        <div className="text-section">
                            <h1>Add a Project</h1>
                            <p>Connect with investors and receive investment for project scalability and growth.</p>
                            <div className="image-section">
                                <img src={addproject} onClick={onRedirect} alt="Add Project" />
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}