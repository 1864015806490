import React, { useContext, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import Slider from "react-slick";
import { breakText } from "@/helpers";
import { TotalCount } from "@/components/utils";
import { dashboardService } from "@/services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import back from "@/assets/back.png";
import { projectsService } from "@/services";

export const Ngo = ({ ngos }) => {
    const { url } = useRouteMatch();
    console.log(ngos);
    const ngo = ngos.data[0];
    const [summary, setSummary] = useState({
        Investments: -1,
        Trees: -1,
        Tree_cost: -1,
    });

    const history = useHistory();

    const goBack = () => {
        history.push(`${url}`);
    }

    const goProfile = () => {
        if (ngo && ngo.id) {
            history.push(`profile?id=${ngo.id}`);
        } else {
            console.error("El objeto 'ngo' es undefined o no tiene una propiedad 'id'");
        }
    }

    const [updatedNgo, setNgo] = useState(ngo);

    useEffect(() => {
        if (ngo && ngo.id) {
            projectsService.getAnalytics('nonprofit', ngo.id).then(data => {
                // Obtén el valor de "ID" de la propiedad "Analytics" de la respuesta
                const analyticsId = data.Analytics.ID;
    
                // Actualiza el estado de updatedNgo con el valor de "ID"
                setNgo(prevNgo => ({
                    ...prevNgo,
                    id: analyticsId, // Asegúrate de que el campo "id" coincida con tu estructura de datos
                }));
            }).catch(error => {
                console.error("Error al obtener datos de análisis:", error);
            });
        }
    }, [ngo]); // Dependencia actualizada a [ngo]
    
    // Resto de tu componente aquí
    
    return (
        <>
            <button className="back-icon btn float-left pl-0" onClick={goBack} >
                <FontAwesomeIcon className="" icon={faArrowLeft} />
                <img src={back} className="back-img" />
            </button>
            <div className="ngos-profile profile">
                <div className="ngo-pro" onClick={goProfile}>
                    <div className="name">
                        {updatedNgo && updatedNgo.Analytics ? (
                            updatedNgo.Analytics.NAME ? (
                                <p>{breakText(updatedNgo.Analytics.NAME, 30)}</p>
                            ) : null
                        ) : null}
                    </div>
                </div>

                <div className="totals-percents marketplace">
                    <div className="totals-container marketplace">
                        {summary.Investments === -1 ? null : <TotalCount title="Investments" number={summary.Investments} simbol="$" delay={1000} />}
                        {summary.Trees === -1 ? null : <TotalCount title="Trees Planted" number={summary.Trees} simbol="" delay={1000} />}
                        {summary.Tree_cost === -1 ? null : <TotalCount title="Tree Cost" number={summary.Tree_cost} simbol="$" delay={1000} />}
                    </div>
                </div>
            </div>
        </>
    )
}
