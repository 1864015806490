export class AppUser {
  constructor(data = {}) {
    this.id = data.id;
    this.username = data.display_name; // Cambiado a data.display_name
    this.name = data.display_name;
    this.legalName = data.legal_name;
    this.email = data.email;
    this.type = data.type; // Cambiado a data.type
    this.status = data.status;
    this.facebook = data.facebook;
    this.instagram = data.instagram;
    this.twitter = data.twitter;
    this.youtube = data.youtube;
    this.linkedIn = data.linked_in;  // Adjusted for snake_case
    this.website = data.website; // Adjusted for snake_case
    this.img = data.img || `${process.env.PUBLIC_URL}/assets/example-profile-img/profile.jpg`;
    this.preferredCurrencyCode = data.preferred_currency_code;
    this.preferredLanguageCode = data.preferred_language_code;
    this.taxId = data.tax_id;
    this.taxAddress = data.tax_address;
    this.createdAt = data.created_at;
    this.createdBy = data.created_by;
    this.modifiedAt = data.modified_at;
    this.modifiedBy = data.modified_by;
  }
}
