import { Modal, Col, Row, Tooltip } from "react-bootstrap";
import { useEffect, useState } from "react";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useWidth } from "@/hooks";
import EcologyIcon from "@/assets/flat-icon/svg/ecology.svg";
import GreenEarthIcon from "@/assets/flat-icon/svg/green-earth.svg";
import { FlatIcon } from "@/components/icon";
import { PaymentMethod } from "./PaymentMethod";
import { useForm } from "react-hook-form";
import { useCounter } from "../../hooks/useCounter";
import { projectsService } from "../../services";
const selectedSection = 'payment';
export const Invest = ({ show, handleClose, handleShow, project,compensation,
    }) => {
         const [quantity, setQuantity] = useState(0);
    const [costConcept, setCostConcept] = useState(0);

    const [mostrarPayment, setMostrarPayment] = useState(false);

    
    const [section, setSection] = useState({ sectionSelected: 'initialSection' });
   
    const {
        ID: recipientId,
        Owner_ID: ownerId,
        analytics
    } = project;


   const carbonSymbol = "kg";

   const [kpi, setKpi] = useState({
     costPerTree: analytics, 
     carbonOffsetPerTree: analytics, 
     carbonOffsetSymbol: carbonSymbol,
     treeCost: null,
   });
   

    const sections =
    {
       
        compensation: { sectionSelected: "compensation", title: "Compensation", back: "ask", to: "payment" },
        payment: { sectionSelected: "payment", title: "Choose the payment method", back: "compensation", to: null },

    };

    const bodyWidth = useWidth();
    const [click, setClick] = useState({ section: "co2", click: 1 });
    const [disabled, setDisabled] = useState(true);
    const count = useCounter();
    const { counter } = count;

    const costCompensation = useState({
        costConcept: 0,
        estimated: 0,
        //total: 0,
    });


    const [cost] = costCompensation;


    useEffect(() => {
        if (counter > 0) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [counter])


    const onClose = (e) => {
        handleClose(e);
        setTimeout(() => {
            setSection({ ...sections.ask });
        }, 500);
    }

    const nextSection = () => {
        setSection({ sectionSelected: selectedSection });
    }

    const backSection = () => {
         setSection({ ...sections.invest });
    }

    const buttonOption = () => {
            nextSection();
            setMostrarPayment(section.sectionSelected === 'payment')
    }
    const handleInputChange = (e) => {
        const value = parseInt(e.target.value);
        setQuantity(value === 0 || isNaN(value) ? '' : value);
    };
    

    return (
        <>
            <Modal show={show} onHide={onClose} centered="true">
                <Modal.Header closeButton>
                    {section.sectionSelected !== "ask" &&
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    Back
                                </Tooltip>
                            }
                        >
                            <button className="btn float-left pl-0" onClick={() => { backSection() }}><FontAwesomeIcon className="text-success back-icon" icon={faArrowCircleLeft} />
                            </button>
                        </OverlayTrigger>
                    }
                    <Modal.Title centered="true">
                        {section.sectionSelected === "compensation" && <>
                            {click.section === "reforest" && <>Reforest </>}
                        </>
                        }
                        {section.title}

                    </Modal.Title>

                    <div style={{ position: "absolute", left: "50%" }}>
                        <div style={{ position: "relative", left: "-50%", top: "40px" }}>
                            {(section.sectionSelected === "compensation" && click.section === "reforest") && <FlatIcon size="xxl"><img src={GreenEarthIcon} alt="icono ecology" style={{ width: "100%" }} /></FlatIcon>}
                        </div>
                    </div>

                </Modal.Header>
                <Modal.Body>



                {section.sectionSelected !== 'payment' && (
  <div className="modal-content">
    <div className="invest">
      <div className="ngo-project">
        <p>Invest</p>
      </div>

      <div className="invest-first-step">
        <div className="text-option">
          {compensation && compensation.section === "reforest" && <p>Choose your trees quantity</p>}
        </div>

        <div className="invest-input">
          <input
            className="input"
            type="number"
            style={{ fontSize: '20px', textAlign: 'right' }}
            placeholder="0"
            value={quantity}
            onChange={handleInputChange}
          />
        </div>

        <div className="totals">
          {compensation && compensation.section === "reforest" && <p>Price per tree</p>}
          <p>${quantity} USD</p>
        </div>
        <div className="grand-total">
          <p>Total:</p>
        </div>

        <div className="grand-total">
          <div></div>
          <select>
            <option>USD</option>
          </select>
        </div>
      </div>
    </div>

    <div className="d-grid gap-2 mt-4">
      <button className={`btn btn-success w-100`} onClick={() => nextSection('payment')}>
        {"Next"}
      </button>
    </div>
  </div>
)}

                    

{section.sectionSelected === "payment" &&
    <PaymentMethod {...cost} recipientId={recipientId} treeCost={kpi.treeCost} ownerId={ownerId} counter={counter} total={quantity} value={quantity}compensation={click.section} onClose={onClose} />
}

                </Modal.Body>
            </Modal>
        </>
    )

}