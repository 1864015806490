import config from "config";
import { authHeader, handleResponse } from "@/helpers";
import { analytics, authenticationService } from "./";
import { Project } from "./";

const currentUser = authenticationService.currentUserValue;

export const projectsService = {
    getAll,
    getById,
    getByNgoId,
    getNGOInvestedProjects,
    getUserInvestedProjects,
    getProjectInvestment,
    createInvestment,
};

function getAnalyticsById(id) {
    const requestOptions = { method: "GET", headers: authHeader() };
    return fetch(`${config.apiUrl}/ProjectAnalytics?$filter=ID eq '${id}'`, requestOptions).then(handleResponse).then(
        resp => resp.value[0]
    );
}

async function getAll() {
    const requestOptions = { method: "GET", headers: authHeader() };
    return fetch(`${config.apiUrl}/projects/`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            // Verificar si resp es un arreglo antes de usar map
            if (Array.isArray(resp)) {
                return Promise.all(resp.map(project => {
                    // Resto de tu código aquí
                    project = { ...project, 
                        Analytics: {
                    "ID": 12,
                    "OWNER_APPUSER_ID": "owner_789",
                    "CENTROID_LATITUDE": 37.7749,
                    "CENTROID_LONGITUDE": -122.4194,
                    "WORKERCOUNT": 32,
                    "CERTIFIER_APPUSER_ID": "cert_567",
                    "FOLLOWER_ID": "follow_345",
                    "INVESTOR_ID": "invest_123",
                    "PLANTINGAREA_M2": 12000,
                    "PLANTINGDENSITY_M2": 150,
                    "SOWCOST": 350,
                    "TREECOST":450,
                    "CONSERVATIONCOST": 250,
                    "BLOCKAREA_M2": 6000,
                    "TOTALBLOCKCAPACITY": 15,
                    "BLOCKPLANTINGCAPACITY": 12,
                    "TOTALPLANTINGCAPACITY": 90,
                    "BLOCKOFFSETAVERAGE_TCO2E_YEAR": 0.6,
                    "BLOCKINITIALCOST": 2100,
                    "BLOCKUPKEEPCOST": 120,
                    "CREATEDAT": "2023-09-07T10:30:00Z",
                    "OFFSETAVERAGE_TCO2E_YEAR_TREE": 0.3,
                    "TOTALTREESPLANTED": 5400,
                    "TOTALOFFSETEXPECTED_TCO2E": 1100,
                    "TOTALBLOCKSPURCHASED": 6,
                    "TOTALGROSS": 11000,
                    "TOTALNET": 8600,
                    "FOLLOWERCOUNT": 1050,
                    "INVESTORCOUNT": 55,
                    "NAME":"pedro",
                }
            };
            return new Project(project);
        }));
    } else {
        // Manejar el caso en que resp no sea un arreglo
        console.error('La respuesta no es un arreglo:', resp);
        throw new Error('La respuesta no es un arreglo');
    }
});
}


function getById(id) {
    // const userId = currentUser.ID || null;
    const requestOptions = { method: "GET", headers: authHeader() };
    return fetch(`${config.apiUrl}/project/${id}`, requestOptions).then(handleResponse)
        .then(resp => {
            console.log(resp)
            return new Project({...resp, Analytics: null});
            return [getAnalyticsById(resp.ID).then(analytics => {
                resp = { ...resp, Analytics: analytics };
                return new Project(resp);
            })];
        }
        );
}

function getByNgoId(ngoId) {
    // const userId = currentUser.ID || null;
    const requestOptions = { method: "GET", headers: authHeader() };
    return fetch(`${config.apiUrl}/Projects?$expand=Owner($expand=AppUser),Boundaries,SiteOwnership&$filter=Owner_AppUser_ID eq ${ngoId}`, requestOptions).then(handleResponse).then(
        resp => Promise.all(resp.value.map(project => {
            return getAnalyticsById(project.ID).then(analytics => {
                project = { ...project, Analytics: analytics };
                return new Project(project);
            });
        }))
    );
}

function getNGOInvestedProjects(userId) {
    const requestOptions = { method: "GET", headers: authHeader() };
    return fetch(`${config.apiUrl}/projects/ngo/${userId}/investments`, requestOptions).then(handleResponse);
}

function getProjectInvestment(projectId) {
    const requestOptions = { method: "GET", headers: authHeader() };
    return fetch(`${config.apiUrl}/project/${projectId}/investments`, requestOptions).then(handleResponse);
}


function getUserInvestedProjects(userId) {
    const requestOptions = { method: "GET", headers: authHeader() };
    return fetch(`${config.apiUrl}/invest/investor/${userId}`, requestOptions).then(handleResponse);
}

function createInvestment(invest) {
    const userId = currentUser.ID;
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ ...invest, Sender_ID: userId }),
        // body: JSON.stringify({
        //     Amount: unit * tree_cost,
        //     Unit: unit,
        //     CostPerUnit: tree_cost,
        //     Currency_code: "USD",
        //     Sender_ID: userId,
        //     Recipient_ID: projectId,
        //     Owner_ID: ownerId,
        // }),
    };
    return fetch(`${config.apiUrl}/projects/invest`, requestOptions).then(handleResponse);
}




// function getByUserId() {
//     const userId = currentUser.ID;
//     const requestOptions = { method: "GET", headers: authHeader() };
//     return fetch(`${config.apiUrl}/NonProfits(${userId})?$expand=Projects`, requestOptions).
//     then(handleResponse)
//     .then(resp => resp.json())
//     .then(user => {
//         return user.Projects;
//     });
// }
export default projectsService;