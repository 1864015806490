import Tree from "../../assets/tree.png";
import useMeasure from "react-use-measure";
import { animated, useSpring, config } from "react-spring";
import { useEffect } from "react";
import { useScrollTop } from "@/hooks";
import { aboutImages } from "@/helpers";
import Slider from "react-slick";

export const About = () => {
    const [ref, bounds] = useMeasure();
    const scrollTop = useScrollTop();
    const images = aboutImages();

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2500,
        autoplaySpeed: 2000
    };

    const [animationTitle, setAnimationTitle] = useSpring(() => ({
        from: { opacity: 0 },
        config: config.slow,
        delay: 100
    }));

    const [animation, setAnimation] = useSpring(() => ({
        from: { opacity: 0, transform: "translateY(10%)" },
        config: config.slow,
        delay: 100
    }));

    useEffect(() => {
        const { top } = bounds;
        if (top > 0 && scrollTop > top - 400) {
            setAnimationTitle.start({ opacity: 1 });
            setAnimation.start({ opacity: 1, transform: "translateY(0%)" });
        }
    }, [bounds, scrollTop, setAnimation, setAnimationTitle]);

    return (
        <section className="about" ref={ref} >
            <animated.h1 style={animationTitle}>What is Ybira?</animated.h1>
            <animated.div style={animation} className="about-card">
                <div className="about-text">
                    <div className="text">
                    <p>
  Ybirá is an app designed for <strong>investors</strong> and <strong>NGOs</strong> (<em>Non-Governmental Organizations</em>) interested in supporting, developing, and investing in <strong>sustainable environmental projects</strong>. It is a marketplace where ONG projects and investors will connect. We utilize <strong>offset technologies and satellite imagery</strong> to enable investors to monitor project developments.
</p>
                    </div>
                    <Slider {...settings}>
                        {images.map((element, index) => (
                            <div className="image" key={index}>

                                <img  className="picture" src={element.url} alt={element.name}  />
                            </div>

                        ))}
                    </Slider>
                </div>
            </animated.div>
        </section>
    );
};
