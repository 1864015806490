import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaypal } from "@fortawesome/free-brands-svg-icons";
import { projectsService } from "@/services";
import { useHistory } from "react-router";
import { currentUserStorage } from "./authStorage";
import config from "config";

export const PaymentMethod = ({ onClose, recipientId, ownerId, compensation, estimated, treeCost, total, value }) => {
    const [showMessage, setShowMessage] = useState(false);
    const currentUserData = currentUserStorage.value.id;
    const emailData = currentUserStorage.value.email;
    const user = currentUserStorage.value.name;
    const id = currentUserData;
    const [paymentMethodSelected, setPaymentMethodSelected] = useState(null);
    const history = useHistory();

    const Investupdate = async () => {
        try {
            console.log(JSON.stringify({
                investorName: id,
                emailAddress: emailData,
                cantidad: value,
                status: 'proceso',
                username: user
            }))
            const response = await fetch(`${config.apiUrl}/invest/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    investorName: id,
                    emailAddress: emailData,
                    cantidad: value,
                    status: 'proceso',
                    username: user
                })
            });
            if (response.ok) {
                const data = await response.json();
                console.log('Estado actualizado:', data);
            } else {
                console.error('Error al actualizar el estado.');
            }
        } catch (error) {
            console.error('Error al enviar la solicitud:', error);
        }
    };

    const [paying, setPaying] = useState({
        loading: false,
        error: '',
        data: [],
        invest: false,
    });

    const [comp, setCompensation] = useState({
        Amount: 0,
        Unit: 0,
        CostPerUnit: 0,
        Currency_code: "USD",
        Recipient_ID: recipientId,
        Owner_ID: ownerId,
    });

    useEffect(() => {
        if (compensation === "co2") {
            setCompensation({
                ...comp,
                Amount: total,
                Unit: value,
                CostPerUnit: treeCost,
            });
        } else if (compensation === "reforest") {
            setCompensation({
                ...comp,
                Amount: total,
                Unit: value,
                CostPerUnit: treeCost,
            });
        }
        return () => {};
    }, [compensation, total, value, treeCost]);

    const handlePayPalPayment = async () => {
        const paypalUrl = `https://www.paypal.me/greenhopecolombia/${total.toFixed(2)}`;
        try {
            await Investupdate();
        } catch (error) {
            console.error('Error al realizar la inversión:', error);
        }
        window.open(paypalUrl, '_blank');
        
    };
    

    const setPayment = async () => {
        setPaying({
            ...paying,
            loading: true
        });

        setShowMessage(true);

        try {
            await Investupdate();
        } catch (error) {
            console.error('Error al realizar la inversión:', error);
        }
    };

    return (
        <div className="invest">
            <div className="ngo-project">
                <p style={{fontSize:'1.5em'}}>Investing</p>
            </div>
            <div className="totals">
                <p>Amount</p>
                <p>
                    {compensation === "co2" && value.toFixed(0)}
                </p>
            </div>
            <div className="totals">
                <p>Total</p>  <p>${total.toFixed(2)} USD</p>
            </div>
            {!paying.invest &&
                <>
                    {total < 1000 ? (
                        <div className="d-grid gap-2 mt-4">
                            <button className={`btn btn-primary w-100`} onClick={handlePayPalPayment}>
                                <FontAwesomeIcon icon={faPaypal} /> Pay with PayPal
                            </button>
                        </div>
                    ) : (
                        <>
                            {showMessage && (
                                <div className="message" style={{ fontSize: '20px', backgroundColor: '#b9ffb0', padding: '10px' }}>
                                    Thank you for being part of it. We will contact you as soon as possible.
                                </div>
                            )}
                            <div className="d-grid gap-2 mt-4">
                                <button className={`btn btn-success w-100`} onClick={setPayment}>
                                    {paying.loading ? "Submitting..." : "Submit Investment Intent"}
                                </button>
                            </div>
                        </>
                    )}
                </>
            }
        </div>
    );
};