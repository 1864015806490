
import {about} from ".";

export const aboutImages = () => {

    const images = [
        about("1.jpg", "Amazon Web Services", 1),
        //about("2.jpg", "Amazon Web Services", 1)
    ];
    return images;
}
