import React, { useState, useEffect } from 'react';
import { LoadContent } from "@/pages/general";
import { authenticationService, userService } from "@/services";
import { Container, Card, Row, Col, Button, Spinner } from 'react-bootstrap';
import { Avatarimg, Details } from './';

export const Account = () => {
    const [user, setUser] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [profileData, setProfileData] = useState({});
    const [loading, setLoading] = useState(false);
    const [serverResponse, setServerResponse] = useState(null);

    useEffect(() => {
        const currentUser = authenticationService.currentUserValue;
        setUser(currentUser);
        setProfileData(currentUser);  // Assuming currentUser has all profile data needed
    }, []);

    const handleSave = () => {
        setLoading(true);

        const saveProfile = new Promise((resolve, reject) => {
            const profileDataToUpdate = { ...profileData, user_type: user.type, user: user.id, img: user.img };
            userService.updateUserProfile(user.id, profileDataToUpdate)
                .then(resolve)
                .catch(reject);
        });

        saveProfile.then(() => {
            if (selectedImage) {
                const formData = new FormData();
                formData.append('image', selectedImage);
                formData.append("file", selectedImage);

                return userService.changeAvatar(formData);
            }
        }).then((res) => {
            if (res) {
                console.log(res);  // Log or handle the response
                setUser({ ...user, DisplayPicture: res.image_url });
            }
            setServerResponse('Profile updated successfully.');
        }).catch((error) => {
            console.error("Error updating profile:", error);
            setServerResponse('Error updating profile.');
        }).finally(() => {
            setLoading(false);
        });
    };

    const handleDetailsChange = (updatedDetails) => {
        setProfileData(updatedDetails);
    };

    if (!user) {
        return null;
    }

    return (
        <LoadContent>
            <main id="account">
                <Container as="main" fluid="md">
                    <Card>
                        <Card.Body>
                            <Row xs={1} sm={1} md={2}>
                                <Col>
                                    <Avatarimg onImageChange={setSelectedImage} />
                                </Col>
                                <Col>
                                    <Details user={profileData} onChange={handleDetailsChange} />
                                    <Button variant="success" onClick={handleSave} disabled={loading}>
                                        {loading ? (
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2'/>
                                        ) : 'Save'}
                                    </Button>
                                    {serverResponse && (
                                        <div className={`alert ${serverResponse.includes('success') ? 'alert-info' : 'alert-danger'}`} role="alert">
                                            {serverResponse}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Container>
            </main>
        </LoadContent>
    );
};

export default Account;
