import "@/scss/auth/index.scss";
import { Modal, Col, Row, Tooltip } from "react-bootstrap";
import { useEffect, useState } from "react";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useWidth, useFetch } from "@/hooks";
import { SignupInvestor, SignupNgo } from "./";
import { userService } from "@/services";

export const Signup = ({ ...props }) => {

    // const url = `https://restcountries.eu/rest/v2/all?fields=name`;

    // const {data} = useFetch(url); 

    
    const [openSection, setOpenSection] = useState("ask");
    const [register, setRegister] = useState({ loading: false, registered: false, error: null });
    const bodyWidth = useWidth();
    const [click, setClick] = useState({section: "investor", click: 1}); 
    const [data, setData] = useState(null);

    useEffect(() => {
        userService.getCountries().then((countries) => {setData(countries)});
    }, [])

    const onClose = (e) => {
        props.handleCloseS(e);
        setTimeout(() => {
            setOpenSection("ask");
        }, 500);
    }

    const saveUser = () => {
        return new Promise((resolve,reject) => {
        setRegister({
            ...register,
            loading: true
        });
        
        setTimeout(() => {
            setRegister({
                ...register,
                loading: false,
                registered: true
            });
            resolve("regstrado");
        }, 2000);
        }).then(()=>{
            setTimeout(() => {
            setRegister({
                    ...register,
                    loading: false,
                    registered: false
            });
            onClose(true);
            }, 1000);
        });
    }

    

    const chooseSection = (sectionVal) =>{
        

        if(bodyWidth < 720){

            if(sectionVal !== click.section){
                setClick({section: sectionVal, click: 1});
            } else {
                setClick({section: click.section, click: click.click + 1});

                 if(click.click === 2){
                    setOpenSection(click.section);
                    setClick({...click, click: 1});
                }
                
            }

        } else {
            setOpenSection(sectionVal);
        }

    }

    return (
        <>
            <Modal show={props.showS} onHide={onClose} centered="true">
                <Modal.Header closeButton>
                    {openSection !== "ask" &&
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    Back
                                </Tooltip>
                            }
                        >
                            <button className="btn float-left pl-0" onClick={() => setOpenSection("ask")}><FontAwesomeIcon className="text-success back-icon" icon={faArrowCircleLeft} />
                            </button>
                        </OverlayTrigger>
                    }
                    <Modal.Title centered="true">{openSection === "ask" ? "Choose the registration type" : "Register"}</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    {openSection === "ask" &&
                        <>
                            <Row>
                                <Col onMouseEnter={bodyWidth > 720 ?() => { setClick({...click, section: "investor"}) }:undefined}>
                                    <button className={`btn w-100 mb-4${click.section === "investor" ? " btn-success" : " btn-light"}`} onClick={() => chooseSection("investor")}>
                                        <span className={`${click.section !== "investor" ? "text-muted" : ""}`}>Investor</span>
                                    </button>
                                </Col>
                                <Col onMouseEnter={bodyWidth > 720 ?() => { setClick({...click, section: "ngo"}) }:undefined}>
                                    <button className={`btn w-100 mb-4${click.section === "ngo" ? " btn-success" : " btn-light"}`} onClick={() => chooseSection("ngo")} >
                                        <span className={`${click.section !== "ngo" ? "text-muted" : ""}`}>NGO</span>
                                    </button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {click.section === "investor" &&
                                        <p className="animate__animated animate__fadeIn">
                                            
As an investor on Ybirá, you have the unique opportunity to directly fund sustainable reforestation, carbon credit projects, and other social and environmental initiatives led by NGOs. Your investment not only seeks financial returns but also supports sustainable development goals, including combating climate change and aiding indigenous communities. Through Ybirá, you can choose projects aligned with your corporate objectives and values, invest transparently, and track the real-time impact of your contribution using advanced satellite and NDVI technology.
                                        </p>
                                    }
                                    {click.section === "ngo" &&
                                        <p className="animate__animated animate__fadeIn">
                                            As an NGO on Ybirá, you gain a platform to showcase your sustainable reforestation, carbon credit, and other social and environmental projects to potential investors. This opportunity allows you to secure direct funding, enhancing your project's reach and impact. With Ybirá's technology, you can provide transparency and real-time updates to your investors, ensuring accountability and trust. Your work contributes to significant global challenges, such as climate change and the support of indigenous communities, leveraging investment to create meaningful change.
                                        </p>
                                    }
                                </Col>

                            </Row>
                        </>

                    }
                    {openSection === "investor" &&
                        <SignupInvestor/>
                    }
                    {openSection === "ngo" &&
                       <SignupNgo data={data}/>
                    }

                    {register.registered && <div class="alert alert-success">Successfully registered</div>}

                </Modal.Body>
        
            </Modal>
        </>
    )

}