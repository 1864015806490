export function getCentroid(boundaries) {

    function Point(x, y) {
        this.x = x;
        this.y = y;
    }

    function Region(points) {
        this.points = points || [];
        console.log(points, "LOOOL")
        this.length = points.length;
    }

    Region.prototype.area = function () {
        var area = 0,
            i,
            j,
            point1,
            point2;

        for (i = 0, j = this.length - 1; i < this.length; j = i, i += 1) {
            point1 = this.points[i];
            point2 = this.points[j];
            area += point1.longitude * point2.latitude;
            area -= point1.latitude * point2.longitude;
        }
        area /= 2;

        return area;
    };

    Region.prototype.centroid = function () {
        var x = 0,
            y = 0,
            i,
            j,
            f,
            point1,
            point2;

        for (i = 0, j = this.length - 1; i < this.length; j = i, i += 1) {
            point1 = this.points[i];
            point2 = this.points[j];
            //f = point1.longitude * point2.latitude - point2.longitude * point1.latitude;
            console.log("Que imprime" + point1, point2, f);  
            x += (point1.longitude) ;
            y += (point1.latitude) ;
        }

        //  f = this.area() * 6;
    
        if (f === 0) {
            // Handle cases where area is zero. 
            // You can return a default centroid or an error.
            return new Point(0, 0); 
        }

        return new Point(x , y);
    };

    console.log(boundaries, "si llega al mapa")

    const region = new Region(boundaries);

    return region.centroid()
}