

export const cards = [
    {
        step: "Step 1",
        description: "Register a Project as NGO.",
        image: `${process.env.PUBLIC_URL}/assets/steps/step1.png`,
    },
    {
        step: "Step 2",
        description: "NGO connect with an investor and receives donation.",
        image: `${process.env.PUBLIC_URL}/assets/steps/step2.png`,
    },
    {
        step: "Step 3",
        description: "Start NGO Project and help local communities in development countries.",
        image: `${process.env.PUBLIC_URL}/assets/steps/step3.png`,
    },
    {
        step: "Step 4",
        description: "Investor receives Donation Certificate.",
        image: `${process.env.PUBLIC_URL}/assets/steps/step4.png`,
    }];


