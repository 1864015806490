import { animated, useSpring } from "react-spring";
import useMeasure from "react-use-measure";

export const TotalCount = (props) => {
  const [ref, bounds] = useMeasure();
  const numSpring = useSpring({
    reset: true,  // Asegura que la animación se reinicie con nuevos valores
    from: { value: 0 },
    to: { value: props.number },
    delay: props.delay,
    config: { duration: 1000 },  // Puedes ajustar la duración según necesites
  });

  return (
    <animated.div ref={ref}>
      <div className="total">
        <div className="total-count">{}
          <animated.p className="global-counter">
          {numSpring.value.to(n => parseInt(n).toLocaleString("en-US"))}
          </animated.p>
          <p className={`global-percent ${props.number > 0 ? "text-success" : "text-danger"}`}>
            {`+${props.simbol}${parseInt(props.number).toLocaleString("en-US")} (100%)`}
          </p>
          <p className="global-title">{props.title}</p>
        </div>
      </div>
    </animated.div>
  );
};
