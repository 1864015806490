import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare, faInstagramSquare, faTwitterSquare } from "@fortawesome/free-brands-svg-icons";
import { useLocation } from "react-router-dom";
import twitter from "@/assets/social/square-x-twitter.svg";
import facebook from "@/assets/social/square-facebook.svg";
import instagram from "@/assets/social/square-instagram.svg";

export const Footer = () => {
    const { pathname: page } = useLocation();
    const today = new Date();
    return (
        <footer className={`${page !== "/" ? "dashboard" : ""}`}>
            <div className="footer-bar">
                <div className="social-buttons">
                    <div className="icons">
                        <a href="https://www.facebook.com/61558800974483" className="icon" target="_blank" rel="noopener noreferrer">
                            <img src={facebook} width={20}/>

                        </a>
                        <a href="https://www.x.com/ybiraorg" className="icon" target="_blank" rel="noopener noreferrer">
                            <img src={twitter} width={20}/>
                        </a>
                        <a href="https://www.instagram.com/ybiraorg" className="icon" target="_blank" rel="noopener noreferrer">
                            <img src={instagram} width={20}/>

                        </a>
                    </div>
                </div>
                <div className="links">
                    <p>Copyright &copy; {today.getFullYear()} / Sitemap / Terms of Use / Privacy Policy / FAQ / Contact - "Powered by "</p>
                </div>
            </div>
        </footer>
    );
};
