import React from "react";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { FlatIcon } from "@/components/icon";

export const CircularProgress = ({ currentT, goalT, number, width, iconSrc, label }) => {
    // Calcula el porcentaje de progreso
    const percentage = currentT && goalT ? (currentT / goalT * 100) : 0;

    return (
        <CircularProgressbarWithChildren
            styles={buildStyles({
                rotation: 0,
                strokeLinecap: "butt",
                textSize: "16px",
                pathTransitionDuration: 0.5,
                pathColor: "rgb(139, 195, 70)",
                textColor: "#f88",
                trailColor: "#d6d6d6",
                backgroundColor: "#3e98c7",
                width: width ? `${width}px` : "100%" // Se ajusta para utilizar la propiedad width si se proporciona
            })}
            value={percentage}
        >
            <div className="d-flex align-items-center flex-column" style={{ marginTop: -5 }}>
                {iconSrc && <FlatIcon size="xxl"><img src={iconSrc} alt="progress icon" style={{width: "100%"}}/></FlatIcon>}
                
                <div className="target-kpi">
                    <p className="current">{currentT ? currentT.toLocaleString("en-US") : '0'}/</p>
                    <div className="goal">
                        <p className="goal-value">{goalT ? goalT.toLocaleString("en-US") : '0'}</p>
                        <p className="small-text">{label}</p>
                    </div>
                </div>
            </div>
        </CircularProgressbarWithChildren>
    );
};
