import React, { useState } from "react"
import { Invest} from "./Invest";

export const InvestButton = React.memo(({project}) => {

    const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

    return (
        <>

            <img src="/assets/button/invest.png" onClick={handleShow} width={150}/>
            <Invest show={show} handleClose={handleClose} project={project} handleShow={handleShow} />
        </>
    )
});
