import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from "config";

export const SearchBar = () => {
    const [projects, setProjects] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchHistory, setSearchHistory] = useState([]);
    const [toggleList, setToggleList] = useState(false);
    const ref = useRef(null);
    
    useEffect(() => {
        // Realizar una solicitud a la API para obtener la lista de proyectos
        fetch(`${config.apiUrl}/projects`)
            .then(response => response.json())
            .then(data => setProjects(data))
            .catch(error => console.error('Error al obtener proyectos:', error));
    }, []);

    const history = useHistory();

    const seeDetails = (project) => {
        history.push(`marketplace/project?id=${project.id}`);
    };

    const onEnter = (e) => {
        if (e.key === 'Enter') {
            onSearch();
        }
    };

    const onDelete = (index) => {
        // Elimina un elemento de la lista de búsqueda
        const updatedSearchResults = [...searchResults];
        updatedSearchResults.splice(index, 1);
        setSearchResults(updatedSearchResults);
    };

    const onSearch = () => {
        if (!searchHistory.includes(searchTerm)) {
            const newSearchHistory = [searchTerm, ...searchHistory];
            if (newSearchHistory.length > 5) {
                newSearchHistory.pop();
            }
            window.localStorage.setItem('search_history', JSON.stringify(newSearchHistory));
            setSearchHistory(newSearchHistory);
        }

        // Filtra los resultados basados en el nombre
        const resultsByName = projects.filter((project) =>
            project.name.toLowerCase().includes(searchTerm.toLowerCase())
        );

        // Filtra los resultados basados en el ID
        const resultsByID = projects.filter((project) =>
            project.id.toString() === searchTerm
        );

        // Combina los resultados de búsqueda por nombre e ID
        const combinedResults = [...resultsByName, ...resultsByID];

        // Actualiza los resultados de la búsqueda
        setSearchResults(combinedResults);
    }

    return (
        <div className="search">
            <input
                ref={ref}
                id="search-input"
                className={`search-input ${toggleList ? 'focused' : ''}`}
                placeholder="Search a project by name or ID..."
                value={searchTerm}
                onChange={(e) => {
                    setSearchTerm(e.target.value);
                    onSearch(); // Realiza la búsqueda en tiempo real mientras se escribe
                }}
                onFocus={() => setToggleList(true)}
                onKeyPress={onEnter}
            />
            <FontAwesomeIcon className="search-icon" icon={faSearch} onClick={onSearch} />

            {searchResults.length > 0 && (
                <ul className="search-list">
                    {searchResults.map((item, i) => (
                        <li key={i} className="search-option">
                            <p
                                style={{ padding: '10px 10px', width: '100%' }}
                                onClick={() => seeDetails(item)}
                            >
                                {item.name}
                            </p>
                            <FontAwesomeIcon icon={faTimes} size="1x" onClick={() => onDelete(i)} />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
export default SearchBar;