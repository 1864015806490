import React, { useState, useRef } from 'react';
import './styles.css';
import photof from '../../assets/photo.png';

export const Avatarimg = ({ onImageChange }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const hiddenFileInput = useRef(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const imgname = event.target.files[0].name;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxSize = Math.max(img.width, img.height);
        canvas.width = maxSize;
        canvas.height = maxSize;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
          img,
          (maxSize - img.width) / 2,
          (maxSize - img.height) / 2
        );
        canvas.toBlob(
          (blob) => {
            const file = new File([blob], imgname, {
              type: "image/png",
              lastModified: Date.now(),
            });

            console.log(file);
            setSelectedFile(file);
            if (onImageChange) {
              onImageChange(file);
            }
          },
          "image/jpeg",
          0.8
        );
      };
    };
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <div>
      <div className="mb-3">
        <label htmlFor="formFile" className="form-label">
          Subir Imagen
        </label>
        <input
          className="form-control"
          type="file"
          id="formFile"
          onChange={handleImageChange}
          ref={hiddenFileInput}
          style={{ display: "none" }}
        />
        <div className="image-upload-container">
          <div className="box-decoration">
            <label
              htmlFor="image-upload-input"
              className="image-upload-label"
            >
              {selectedFile ? selectedFile.name : "Choose an image"}
            </label>
            <div onClick={handleClick} style={{ cursor: "pointer" }}>
              {selectedFile ? (
                <img
                  src={URL.createObjectURL(selectedFile)}
                  alt="upload image"
                  className="img-display-after"
                />
              ) : (
                <img
                  src={photof}
                  alt="upload image"
                  className="img-display-before"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Avatarimg;
