import { AppUser, ProjectAnalytics } from "..";

export class Project {
  constructor(data = {}) {
    this.id = data.id;
    this.createdAt = data.created_at;
    this.createdBy = data.created_by;
    this.modifiedAt = data.modified_at;
    this.modifiedBy = data.modified_by;
    this.ownerId = data.owner;
    this.ownerUsername = data.owner_profile ? data.owner_profile.username : null;  // Añadir el nombre de usuario del propietario
    this.name = data.name;
    this.description = [data.description_line1, data.description_line2, data.description_line3];
    this.logo = data.logo_type;  
    this.banner = data.banner_type || `${process.env.PUBLIC_URL}/assets/ngo-project-1.jpeg`;
    this.countryCode = data.country_code;
    this.currencyCode = data.currency_code;
    this.centroidLat = data.centroid_latitude;
    this.centroidLon = data.centroid_longitude;
    this.siteId = data.site_ownership_id;
    this.plantingArea = data.planting_area_m2;
    this.plantingDensity = data.planting_density_m2;
    this.workerCount = data.worker_count;
    this.certifierId = data.certifier_appuser_id;
    this.goalT = this.plantingArea / this.plantingDensity;
    this.treeCost = parseFloat(data.tree_cost);
    this.boundaries = data.boundaries || null;
    this.ownerShip = data.site_ownership ? data.site_ownership.description : null;
    this.owner = data.owner_profile ? new AppUser(data.owner_profile) : null;
    this.analytics = data.analytics ? new ProjectAnalytics(data.analytics) : null;
    this.amountPerYear = data.amount_per_year;
    this.durationYears = data.duration_years;
    this.photos = data.photos || [];
    this.sdgs = data.sdgs || [];
    this.phase = data.phase;
    this.startDate = data.start_date;
    this.endDate = data.end_date;
    this.carbonCreditGeneration = data.carbon_credit_generation;
    this.taxExemption = data.tax_exemption;
  }
}
